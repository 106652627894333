<template>
  <div class="text botcells-conversation">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image
        :sender="con.sender"
        v-if="con.type === 'bot' && lastMessageGroup"
      />
      <div class="full-width">
        <div
          v-if="con.data.introMessage"
          class="botcells-text"
          :class="getColor()"
        >
          <p>
            {{ con.data.introMessage }}
          </p>
        </div>
        <div class="botcells-flight-card">
          <div class="botcells-flight-header v-row align-center">
            <div class="v-flex-6">
              <svg
                width="19px"
                height="20px"
                viewBox="0 0 19 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
                <title>flight</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="Icons"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Two-Tone"
                    transform="translate(-306.000000, -3124.000000)"
                  >
                    <g id="Maps" transform="translate(100.000000, 3068.000000)">
                      <g
                        id="Two-Tone-/-Maps-/-flight"
                        transform="translate(204.000000, 54.000000)"
                      >
                        <g>
                          <polygon
                            id="Path"
                            points="0 0 24 0 24 24 0 24"
                          ></polygon>
                          <path
                            d="M10,19 L8,20.5 L8,22 L11.5,21 L15,22 L15,20.5 L13,19 L13,13.5 L21,16 L21,14 L13,9 L13,3.5 C13,2.67 12.33,2 11.5,2 C10.67,2 10,2.67 10,3.5 L10,9 L2,14 L2,16 L10,13.5 L10,19 Z"
                            id="🔹-Primary-Color"
                            fill="white"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="v-flex-6 text-right">
              <p class="botcells-text-label">Confirmation Number</p>
              <p class="text-white">{{ con.data.confirmation_number }}</p>
            </div>
          </div>
          <div class="botcells-flight-body">
            <div class="v-row">
              <div class="v-flex-6">
                <p class="botcells-text-label">{{ $t("Passengers") }}</p>
              </div>
              <div class="v-flex-6 text-right">
                <p class="botcells-text-label">{{ $t("Seats") }}</p>
              </div>
            </div>
            <div
              class="v-row"
              v-for="passenger in con.data.passengers"
              :key="passenger"
            >
              <div class="v-flex-6">
                <p class="">{{ passenger }}</p>
              </div>
              <div class="v-flex-6 text-right"></div>
            </div>
            <hr />
            <div v-for="(stop, index) in con.data.flight_info" :key="index">
              <div class="mt-3">
                <h4>{{ stop.stops_number }}</h4>
              </div>
              <div class="v-row">
                <div class="v-flex-4">
                  <p class="botcells-text-label">{{ $t("Flight") }}</p>
                  <p>{{ stop.flight_number }}</p>
                </div>
                <div class="v-flex-4 text-center">
                  <p class="botcells-text-label">{{ $t("Departs") }}</p>
                  <p class="">{{ stop.flight_schedule.departure_time }}</p>
                </div>
                <div class="v-flex-4 text-right">
                  <p class="botcells-text-label">{{ $t("Arrives") }}</p>
                  <p>{{ stop.flight_schedule.arrival_time }}</p>
                </div>
              </div>
              <div class="v-row align-center mt-3">
                <div class="v-flex-4">
                  <p class="botcells-text-label">
                    {{ stop.departure_airport.city }}
                  </p>
                  <p class="text-primary">
                    {{ stop.departure_airport.airport_code }}
                  </p>
                </div>
                <div class="v-flex-4 text-center">
                  <svg
                    width="19px"
                    class="primary-svg"
                    height="20px"
                    viewBox="0 0 19 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
                    <title>flight</title>
                    <desc>Created with Sketch.</desc>
                    <g
                      id="Icons"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Two-Tone"
                        transform="translate(-306.000000, -3124.000000)"
                      >
                        <g
                          id="Maps"
                          transform="translate(100.000000, 3068.000000)"
                        >
                          <g
                            id="Two-Tone-/-Maps-/-flight"
                            transform="translate(204.000000, 54.000000)"
                          >
                            <g>
                              <polygon
                                id="Path"
                                points="0 0 24 0 24 24 0 24"
                              ></polygon>
                              <path
                                d="M10,19 L8,20.5 L8,22 L11.5,21 L15,22 L15,20.5 L13,19 L13,13.5 L21,16 L21,14 L13,9 L13,3.5 C13,2.67 12.33,2 11.5,2 C10.67,2 10,2.67 10,3.5 L10,9 L2,14 L2,16 L10,13.5 L10,19 Z"
                                id="🔹-Primary-Color"
                                fill="#1D1D1D"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="v-flex-4 text-right">
                  <p class="botcells-text-label">
                    {{ stop.arrival_airport.city }}
                  </p>
                  <p class="text-primary">
                    {{ stop.arrival_airport.airport_code }}
                  </p>
                </div>
              </div>
              <hr />
            </div>

            <div class="v-row mt-2">
              <div class="v-flex-6">
                <p class="botcells-text-label">Total</p>
              </div>
              <div class="v-flex-6 text-right">
                <p>{{ con.data.total }}</p>
              </div>
            </div>
            <div>
              <div class="mt-2">
                <a :href="con.data.details_url" target="_blank">
                  <b-btn>
                    {{ $t("View Details") }}
                  </b-btn>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="lastMessageGroup"
      class="chat-date d-flex"
      :class="getAlignment()"
    >
      {{ common.formatDate(con.date) }}
    </div>
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
export default {
  name: "travelData",
  components: {
    userChatImage,
  },
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      common,
    };
  },
  methods: {
    getAlignment() {
      if (this.con.type === "user") {
        return "justify-end";
      } else if (this.con.type === "bot") {
        return "justify-start";
      }
    },
    getColor() {
      if (this.con.type === "user") {
        return "botcells-user-text";
      } else if (this.con.type === "bot") {
        return "botcells-bot-text";
      }
    },
  },
  computed: {
    lastMessageGroup() {
      return this.$store.state.messages.conversationList[this.index]
        .lastMessageGroup;
    },
  },
};
</script>

<style lang="scss">
.botcells-flight-card {
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 10px;
  p {
    font-size: 13px;
  }
  .botcells-flight-header {
    background: var(--primaryColor);
    padding: 8px;
  }
  .text-primary {
    font-size: 30px;
    color: var(--primaryColor);
    font-weight: 300;
  }
  .botcells-flight-body {
    padding: 15px;
  }
  svg {
    fill: var(--primaryColor);
  }
}
</style>
