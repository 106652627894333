<template>
  <div ref="modalContainer" class="botcells-wdiget-body">
    <div
      :class="[
        'botcells-modal-chat',
        { 'botcells-chat-body-shrinked': inputPulledUp },
      ]"
    >
      <div class="botcells-modal-chat-container">
        <transition-group name="slide">
          <div
            v-for="(con, index) in conversation"
            :key="`${con.slug}${index}`"
          >
            <component
              v-if="inputPulledUp || !isInputPullingSlug(con.slug)"
              :is="getFileName(con.slug)"
              :con="con"
              :index="index"
              @postBack="postBack"
              @postText="postText"
              @postFile="postFile"
              @postEvent="postEvent"
              @postCustom="postCustom"
              @update-input="handleUpdateInput"
            />
          </div>
        </transition-group>
      </div>
    </div>
    <div
      class="botcells-chat-infos"
      v-if="!(this.$store.state.core.widgetParameters.hidePoweredBy === true)"
    >
      <a dir="ltr" href="https://botcells.com/" target="_blank">
        <botcells-logo :width="10" :height="20" />
        <span class="ml-0">Powered by BOTCELLS</span>
      </a>
    </div>
    <div
      :class="[
        'botcells-chat-action',
        { 'botcells-chat-action-pulled-up': inputPulledUp },
      ]"
      v-show="!inputDisabled"
    >
      <snackbar v-if="!connected">
        <bdi>{{
          $t("You are disconnected we are trying to reconnect again")
        }}</bdi>
      </snackbar>
      <div
        class="d-flex align-center relative justify-space-between full-width"
      >
        <record-voice
          v-if="
            !(this.$store.state.core.widgetParameters.hideRecorder === true) &&
            showRecorder &&
            connected
          "
          @record="handleRecordCompleteAction"
          @cancel="handleRecordCancelAction"
        />
        <form
          class="botcells-user-input d-flex align-center"
          @submit.prevent="sendMessage"
        >
          <presist-menu
            style="height: 28px"
            @postBack="postBack"
          ></presist-menu>

          <input
            v-if="!inputPulledUp"
            v-model="userInput"
            :disabled="!connected || inputDisabled"
            type="text"
            class="botcells-user-input-field"
            id="botcells-user-input-field"
            spellcheck="true"
            :placeholder="
              $store.state.core.widgetParameters.inputPlaceHolder ||
              'Write a reply'
            "
          />
          <div v-else class="botcells-user-input-label">
            <p>{{ inputLabel }}</p>
          </div>
        </form>
        <div
          v-if="connected"
          class="d-flex align-center justify-end"
          style="width: 70px"
        >
          <input
            v-if="
              !$store.state.core.widgetParameters.showLocationLocator &&
              !(this.$store.state.core.widgetParameters.hideShareFile === true)
            "
            type="file"
            class="botcells-file-uploader"
            @change="uploadFile"
            ref="file"
          />
          <span
            v-if="
              !$store.state.core.widgetParameters.showLocationLocator &&
              !(this.$store.state.core.widgetParameters.hideShareFile === true)
            "
            @click="$refs.file.click()"
            class="botcells-svg d-flex align-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.798"
              height="19.798"
              viewBox="0 0 19.798 19.798"
            >
              <g
                id="prefix__noun_Paperclip_2766516"
                transform="translate(-.086 -.086)"
                style="opacity: 0.7"
              >
                <g
                  id="prefix__Group_21"
                  data-name="Group 21"
                  transform="translate(.086 .086)"
                >
                  <path
                    id="prefix__Path_6"
                    d="M7.636 4.242V14A2.969 2.969 0 0 1 1.7 14V3.394a1.7 1.7 0 1 1 3.394 0v9.757a.424.424 0 1 1-.848 0V4.242h-1.7v8.908a2.121 2.121 0 0 0 4.242 0V3.394a3.394 3.394 0 1 0-6.788 0V14a4.666 4.666 0 0 0 9.333 0V4.242z"
                    data-name="Path 6"
                    transform="rotate(-135 7.4 8.532)"
                  />
                </g>
              </g>
            </svg>
          </span>

          <div
            class="widget-action-trigger"
            :class="{ activeActions: activeActions }"
          >
            <v-icon
              class="plug-trigger"
              v-if="$store.state.core.widgetParameters.showLocationLocator"
              @click="activeActions = !activeActions"
              >mdi-plus
            </v-icon>

            <widget-actions
              v-if="$store.state.core.widgetParameters.showLocationLocator"
            >
              <location-locator @getLocation="postLocation" class="nds nd1" />
              <div id="file-uploader">
                <input
                  type="file"
                  class="botcells-file-uploader"
                  @change="uploadFile"
                  ref="file"
                />
                <div @click="$refs.file.click()" class="botcells-svg nds nd2">
                  <div class="tooltip">
                    <v-icon>mdi-attachment</v-icon>
                    <span class="tooltiptext">{{ $t("send attachment") }}</span>
                  </div>
                </div>
              </div>
            </widget-actions>
          </div>

          <send-button v-if="userInput.length" @click="handleSendAction" />
          <record-button
            v-if="
              connected &&
              !userInput.length &&
              !(
                this.$store.state.core.widgetParameters
                  .hideVoiceNoteRecorder === true
              )
            "
            @click="handleRecordAction"
          />
          <div id="sound"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotSocket from "../modules/botSocket";
import common from "../utils/common";
import botcellsLogo from "./UIElements/botcellsLogo";
import recordButton from "./UIElements/recordButton";
import RecordVoice from "./UIElements/recordVoice.vue";
import sendButton from "./UIElements/sendButton";
import snackbar from "./UIElements/snackbar";
import widgetActions from "./common/widgetActions";
import locationLocator from "./locationLocator";
import presistMenu from "./menu/presistMenu";
import adaptiveCard from "./messagesType/adaptiveCard";
import attachmentData from "./messagesType/attachment";
import audioData from "./messagesType/audioData";
import bookAppointmentData from "./messagesType/bookAppointmentData";
import calenderBookingData from "./messagesType/calenderBooking";
import cardDateTimeInputData from "./messagesType/cardDateTimeInputData";
import escalateData from "./messagesType/escalateData";
import flightStatusData from "./messagesType/flightStautsData";
import galleryData from "./messagesType/galleryData";
import gifData from "./messagesType/gifData";
import heroData from "./messagesType/heroData";
import imageData from "./messagesType/imageData";
import linkedImageData from "./messagesType/linkedImageData";
import mapData from "./messagesType/mapData";
import multiInputData from "./messagesType/multiInputData";
import ocrData from "./messagesType/ocrData.vue";
import orderData from "./messagesType/orderCard";
import receiptData from "./messagesType/receiptData";
import textData from "./messagesType/textData";
import flightPassngersData from "./messagesType/travelData";
import triviaQuestionData from "./messagesType/triviaQuestionData";
import typingData from "./messagesType/typingData";
import userInputData from "./messagesType/userInputData";
import videoData from "./messagesType/videoData";
import weatherData from "./messagesType/weatherData";

export default {
  name: "WidgetBody",
  components: {
    botcellsLogo,
    textData,
    imageData,
    heroData,
    triviaQuestionData,
    galleryData,
    typingData,
    videoData,
    audioData,
    adaptiveCard,
    snackbar,
    sendButton,
    recordButton,
    gifData,
    flightStatusData,
    flightPassngersData,
    cardDateTimeInputData,
    weatherData,
    receiptData,
    mapData,
    linkedImageData,
    attachmentData,
    userInputData,
    multiInputData,
    presistMenu,
    escalateData,
    calenderBookingData,
    locationLocator,
    widgetActions,
    orderData,
    bookAppointmentData,
    RecordVoice,
    ocrData,
  },
  props: {
    sessionActive: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    modalActive: {
      type: Boolean,
      default: false,
    },
    onFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInput: "",
      inputLabel: "",
      slugs: [
        "text",
        "image",
        "attachment",
        "hero",
        "gallery",
        "typing",
        "video",
        "audio",
        "gif",
        "trivia-question",
        "flight-status",
        "flight-passngers",
        "card-date-time-input",
        "weather",
        "receipt",
        "map",
        "linked-image",
        "user-input",
        "multi-input",
        "escalate",
        "calender-booking",
        "order",
        "book-appointment",
        "ocr",
      ],
      textSlugs: ["prompt-input", "prompt-date", "prompt-multi-input"],
      adaptiveCards: ["receipt", "weather"],
      bottomInputSlugs: [""],
      activeActions: false,
      showRecorder: false,
    };
  },

  computed: {
    socket() {
      return this.$store.state.core.socket;
    },
    conversation() {
      return this.$store.state.messages.conversationList;
    },
    connected() {
      return this.$store.state.messages.connected;
    },
    inputDisabled() {
      return this.$store.state.messages.inputDisabled;
    },
    inputPulledUp() {
      if (this.conversation && this.conversation.length)
        return this.isInputPullingSlug(
          this.conversation[this.conversation.length - 1].slug
        );
      return false;
    },
  },
  watch: {
    "conversation.length": function () {
      if (common) common.scrollHeight();
    },

    userInput() {
      let arabic = /[\u0600-\u06FF]/;
      let input = document.getElementById("botcells-user-input-field");
      if (arabic.test(this.userInput)) {
        input.style.direction = "rtl";
      } else {
        input.style.direction = "ltr";
      }
    },
    conversation() {},
  },
  mounted() {
    // listen to enter press
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") this.submitFakeInput();
    });
  },
  methods: {
    async sendMessage() {
      if (this.userInput && this.userInput.replace(/\s/g, "").length) {
        try {
          this.userInput = common.sanitizeString(this.userInput);

          BotSocket.sendMessage({
            text: this.userInput,
            type: "message",
            slug: "text",
          });
          await this.$store.dispatch("pushMessage", {
            slug: "text",
            payload: this.userInput,
            type: "user",
            lastMessageGroup: true,
          });
          this.userInput = "";
        } catch (err) {
          // error to be handled
          console.log(err);
        }
      }
    },
    async uploadFile(event) {
      const file = event.target.files[0];
      let formObject = new FormData();
      this.activeActions = false;
      formObject.append("file", file);

      const showLoading = () => {
        this.$store.dispatch("pushMessage", {
          slug: "typing",
          type: "bot",
          duration: 2900,
          lastMessageGroup: true,
        });
      };
      showLoading();
      let loading = setInterval(showLoading, 3000);

      this.$http_upload
        .post("/file/upload", formObject)
        .then((response) => {
          let slug = response.data.type;
          if (file.type.match("image/*")) {
            slug = "image";
          } else if (file.type.match("audio/*")) {
            slug = "audio";
          }
          let file_url = response.data.url;
          this.$store.dispatch("pushMessage", {
            slug: slug,
            mediaUrl: file_url,
            type: "user",
            fileName: response.data.name,
            lastMessageGroup: true,
          });

          let attachmentObject = {
            type: "attachment",
            url: file_url,
            attachment_type: response.data.type,
          };

          BotSocket.sendMessage({
            slug: slug,
            type: slug == "audio" ? slug : "message",
            mediaUrl: file_url,
            file_name: response.data.name,
            text: JSON.stringify(attachmentObject),
          });
          clearInterval(loading);
        })
        .catch((error) => {
          clearInterval(loading);
        });
    },
    postFile(file) {
      let attachmentObject = {
        type: "attachment",
        url: file.url,
        attachment_type: file.type,
      };

      BotSocket.sendMessage({
        slug: file.type,
        type: "message",
        file_name: file.name,
        text: JSON.stringify(attachmentObject),
      });
    },
    postEvent(eventName) {
      BotSocket.sendMessage({
        event: eventName,
        type: "report_event",
      });
    },
    async postBack(postData) {
      await BotSocket.sendMessage({
        text: postData.value || postData.payload,
        type: "message",
        postback: postData,
      });
    },
    postText(postData) {
      BotSocket.sendMessage({
        text: postData,
        type: "message",
        slug: "text",
      });
    },
    postCustom(postData) {
      BotSocket.sendMessage(postData);
    },
    postLocation(cord) {
      this.activeActions = false;
      let text = JSON.stringify(cord);
      // BotSocket.sendMessage({
      //   type: 'location',
      //   text,
      //   coordinates: { lat: cord.latitude, long: cord.longitude },
      // })
      this.$store.dispatch("pushMessage", {
        slug: "linked-image",
        mediaUrl: cord.image,
        type: "user",
        fileName: "location",
        lastMessageGroup: true,
      });

      let attachmentObject = {
        type: "attachment",
        url: cord.image,
        latitude: cord.latitude,
        longitude: cord.longitude,
        attachment_type: "location",
      };

      BotSocket.sendMessage({
        slug: "image",
        type: "message",
        file_name: "location",
        text: JSON.stringify(attachmentObject),
      });
    },
    getFileName(slug) {
      if (this.slugs.includes(slug)) {
        return slug + "-data";
      } else if (this.textSlugs.includes(slug)) {
        return "text-data";
      }
    },
    // decides whether the given slug will pull the actionInput up or not
    isInputPullingSlug(slug) {
      return this.bottomInputSlugs.includes(slug);
    },
    handleUpdateInput(e) {
      this.userInput = e;
      if (e.label) this.inputLabel = e.label;
    },
    handleSendAction() {
      this.sendMessage();
    },
    submitFakeInput() {
      if (this.onFocus && this.inputPulledUp) this.sendMessage();
    },
    handleRecordAction() {
      this.showRecorder = true;
    },
    handleRecordCompleteAction(blob) {
      this.showRecorder = false;
      this.uploadFile({
        target: {
          files: [
            new File([blob], `record${Date.now()}.wav`, { type: blob.type }),
          ],
        },
      });
    },
    handleRecordCancelAction() {
      this.showRecorder = false;
    },
  },
};
</script>

<style lang="scss" src="../style/components/widgetBody.scss"></style>
