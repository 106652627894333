<template>
  <img
    src="https://cdn.botcells.com/Main%20Icon.svg"
    :width="width"
    :height="height"
  />

  <!-- <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 150.000000 150.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M996 983 c-3 -3 -6 -34 -6 -68 l0 -61 -144 -83 -143 -82 -59 35 c-32
19 -62 32 -66 29 -18 -10 -5 -24 52 -55 33 -18 63 -41 66 -50 7 -24 17 -23 29
4 6 14 63 53 145 100 l135 77 30 -19 c17 -10 78 -47 138 -81 l107 -63 0 -161
0 -160 -132 -75 c-72 -41 -134 -76 -138 -78 -15 -6 -283 153 -288 170 -6 23
-18 23 -25 -1 -2 -10 -32 -33 -66 -52 -33 -18 -61 -39 -61 -46 0 -20 14 -16
78 23 l60 35 141 -81 141 -82 0 -65 c0 -87 23 -82 30 6 l5 63 135 78 134 78
62 -34 c68 -38 74 -40 74 -20 0 7 -27 28 -60 45 l-60 32 0 164 0 164 60 33
c33 18 60 38 60 45 0 20 -15 16 -68 -17 -27 -16 -55 -30 -63 -30 -7 0 -73 34
-146 76 l-133 76 0 63 c0 59 -8 83 -24 68z"
      />
      <path
        d="M486 597 c-14 -10 -16 -26 -14 -102 l3 -90 29 -3 c16 -2 32 2 37 10
5 7 9 50 9 95 0 71 -3 84 -19 93 -24 13 -24 13 -45 -3z m34 -93 c0 -56 -3 -75
-12 -72 -8 3 -14 27 -16 63 -3 62 1 85 18 85 6 0 10 -30 10 -76z"
      />
      <path
        d="M688 599 c-15 -8 -18 -24 -18 -92 0 -45 4 -88 9 -95 5 -8 21 -12 37
-10 25 2 29 8 32 36 4 35 -12 44 -21 12 -3 -11 -10 -20 -16 -20 -7 0 -11 28
-11 76 0 56 3 75 13 72 6 -3 13 -17 15 -31 5 -39 22 -33 22 8 0 24 -6 37 -19
45 -23 12 -22 12 -43 -1z"
      />
      <path
        d="M777 604 c-4 -4 -7 -52 -7 -106 l0 -98 45 0 c33 0 45 4 45 15 0 9 -9
15 -25 15 -14 0 -25 5 -25 10 0 6 9 10 20 10 12 0 20 7 20 16 0 9 -9 18 -20
21 -16 4 -20 14 -20 43 0 32 4 39 23 42 37 5 27 33 -13 36 -20 2 -39 0 -43 -4z"
      />
      <path
        d="M877 604 c-4 -4 -7 -52 -7 -106 l0 -98 40 0 c29 0 40 4 40 15 0 8 -9
15 -20 15 -17 0 -19 8 -22 87 -3 79 -12 106 -31 87z"
      />
      <path
        d="M967 604 c-4 -4 -7 -52 -7 -106 l0 -98 45 0 c33 0 45 4 45 15 0 9 -9
15 -25 15 -24 0 -24 2 -27 87 -3 79 -12 106 -31 87z"
      />
      <path
        d="M1072 598 c-7 -7 -12 -33 -12 -58 0 -37 5 -52 25 -70 26 -25 32 -40
15 -40 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -9 0 -15 -9 -15 -25 0 -30 32 -50
59 -36 38 21 35 56 -8 95 -21 19 -30 76 -11 76 6 0 10 -14 10 -31 0 -43 34
-37 38 7 5 50 -44 84 -76 52z"
      />
      <path
        d="M360 500 l0 -101 38 3 37 3 0 95 0 95 -37 3 -38 3 0 -101z m55 30 c0
-26 -5 -45 -12 -47 -9 -3 -13 10 -13 47 0 37 4 50 13 48 7 -3 12 -22 12 -48z
m3 -88 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z"
      />
      <path
        d="M570 591 c0 -5 7 -11 15 -15 12 -4 15 -24 15 -91 0 -50 4 -85 10 -85
6 0 10 35 10 85 0 67 3 87 15 91 28 11 15 24 -25 24 -22 0 -40 -4 -40 -9z"
      />
    </g>
  </svg> -->
</template>

<script>
export default {
  name: "botcellsLogo",
  props: {
    height: {
      type: Number,
      default: () => {
        return 17;
      },
    },
    width: {
      type: Number,
      default: () => {
        return 17;
      },
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  fill: var(--primaryColor) !important;
}
.primary-svg {
  fill: var(--primaryColor) !important;
}
</style>
