<template>
  <div class="text botcells-conversation botcells-datetime-picker">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image
        :sender="con.sender"
        v-if="con.type === 'bot' && lastMessageGroup"
      />
      <div class="full-width">
        <div class="botcells-text" :class="getColor()" v-if="con.payload">
          <p v-html="con.payload"></p>
        </div>
        <v-menu
          v-model="menu2"
          ref="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          nudge-top="250"
          max-width="300"
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              readonly
              v-if="!disabled"
              class="mt-3"
              color="primary"
              v-bind="attrs"
              block
              v-on="on"
              >{{ con.buttonTitle }}{{ date }}</v-btn
            >
          </template>
          <v-card class="botcells-datetime-picker">
            <v-time-picker
              v-model="date"
              full-width
              v-if="con.hasTime"
              @click:minute="postBack(date)"
              color="primary"
            ></v-time-picker>
            <v-date-picker
              v-model="date"
              color="primary"
              v-else
              @input="postBack(date)"
            ></v-date-picker>
            <div class="text-center pr-2 pl-2 pb-2 d-block">
              <v-btn
                outlined
                color="primary"
                block
                @click="cancelDialog"
                class="pa-0"
                >{{ con.cancelButtonLabel }}</v-btn
              >
            </div>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div
      v-if="lastMessageGroup"
      class="chat-date d-flex"
      :class="getAlignment()"
    >
      {{ common.formatDate(con.date) }}
    </div>
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
export default {
  name: "travelData",
  components: {
    userChatImage,
  },
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      common,
      date: "",
      time: "",
      menu2: "",
      disabled: false,
    };
  },
  mounted() {
    this.$store.dispatch("setInputDisabled", true);
  },
  methods: {
    getAlignment() {
      if (this.con.type === "user") {
        return "justify-end";
      } else if (this.con.type === "bot") {
        return "justify-start";
      }
    },
    getColor() {
      if (this.con.type === "user") {
        return "botcells-user-text";
      } else if (this.con.type === "bot") {
        return "botcells-bot-text";
      }
    },
    postBack(value) {
      this.menu2 = false;
      this.$emit("postText", value);
      this.disabled = true;
      this.$store.dispatch("pushMessage", {
        slug: "text",
        payload: value,
        type: "user",
        lastMessageGroup: true,
      });
      this.$store.dispatch("setInputDisabled", false);
    },
    cancelDialog() {
      this.menu2 = false;
      this.disabled = true;
      this.$emit("postCustom", {
        type: "message",
        text: "cancel_all_dialogs",
        cancelRedirectBlock: this.con.cancelRedirectBlock,
      });
      this.$store.dispatch("setInputDisabled", false);
    },
  },
  computed: {
    lastMessageGroup() {
      return this.$store.state.messages.conversationList[this.index]
        .lastMessageGroup;
    },
  },
};
</script>

<style lang="scss">
.botcells-datetime-picker .v-date-picker-table {
  height: auto !important;
}
</style>
