export const WIDGET_DEFAULTS = {
  en_US: {
    header: "Chat with Botcells",
    subHeader:
      "We’re here to answer your questions about Company. Ask us anything!",
    offlineMessage:
      "We’re here to answer your questions about Company. Ask us anything!",
    botName: "BOTCELLS",
    startButtonLabel: "Start now",
    inputPlaceHolder: "Write a reply",
    mainColor: "#473FB3",
    userSideColor: "#473FB3",
    userSideFontColor: "white",
    headerColor: "#473FB3",
    headerFontColor: "white",
    chatBotAnswerColor: "#eaf0f7",
    chatBotAnswerFontColor: "#000",
    bubbleButtonColor: "#fff",
    avatarBackgroundColor: "#eee",
    buttonsColor: "#473FB3",
    buttonsBackgroundColor: "#fff",
    buttonsBorderColor: "#473FB3",
    typingSound: "",
    messageSound: "https://cdn.botcells.com/notification.mp3",
    logo: "https://cdn.botcells.com/Icon%20White.svg",
    botAvatar: "https://cdn.botcells.com/web.png",
    agentDefaultAvatar: "https://cdn.botcells.com/2023-08-07/Main logo.png",
    openBubbleImage: "https://cdn.botcells.com/2023-08-07/Main logo.png",
    closeBubbleImage: "https://cdn.botcells.com/1595585747.png",
    logoColorType: "white",
    botAvatarColorType: "colored",
    botAvatarHasFullWidth: false,
    openBubbleImageColorType: "colored",
    openBubbleImageFullWidth: false,
    offLineMessageBackgroundColor: "#fff",
    offLineMessageFontColor: "#000",
    offLineMessageAppearance: "once",
    avatarType: "circle",
    customFont: false,
    font: "Roboto",
    fontSize: 14,
    chatBubble: "curvey",
    direction: "ltr",
    position: "right",
    hideFaqs: false,
    hideForms: false,
    displayIntroButtons: false,
    rememberUser: true,
    facebookLink: "",
    twitterLink: "",
    whatsappLink: "",
    demoBackground: "",
    showLocationLocator: false,
    googleMapApiKey: "",
    buttonsSingleClick: false,
    whatsAppChatButtonLink: "",
    whatsAppMandatory: false,
    whatsAppBtnColor: "#01AF9C",
    webBtnColor: "#FFC108",
    whatsAppBtnImg: "https://cdn.botcells.com/whatsapp.png",
    webBtnImg: "https://cdn.botcells.com/web.png",
    faqsTitle: "Find an answer your self",
    faqsPlaceholder: "Search FAQs",
    introButtonsBackground: "#fff",
    introButtonsColor: "#000",
  },
};
