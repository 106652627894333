<template>
  <span
    class="botcells-svg ml-5 botcells-chart-send-button botcells-chat-button d-flex align-center"
    @click="$emit('click', $event)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="prefix__noun_send_2498628"
        transform="translate(-151.255 -154.027)"
      >
        <g
          id="prefix__Group_3"
          data-name="Group 3"
          transform="translate(151.255 154.027)"
        >
          <path
            id="prefix__Path_4"
            d="M166.814 161.388l-14.419-7.3c-.647-.208-1.265.148-1.118.891l2.06 5.581 8.828 1.455-8.828 1.484-2.06 5.581c-.147.742.471 1.1 1.118.891l14.419-7.333a.661.661 0 0 0 0-1.25z"
            data-name="Path 4"
            transform="translate(-151.255 -154.027)"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "sendButton",
};
</script>

<style lang="css" scoped>
svg {
  fill: var(--primaryColor) !important;
}
</style>
