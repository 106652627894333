import store from "../store";
import messages from "../utils/messages";

export default class BotSocket {
  static config = {
    ws_url: "",
    reconnect_timeout: 3000,
    max_reconnect: 30,
    enable_history: false,
    env: process.env.VUE_APP_ENVIROMENT,
  };

  static options = {
    use_sockets: true,
    enable_history: true,
  };

  static socket = null;
  static reconnect_count = 0;
  static guid = null;
  static current_user = null;
  static bot_id = null;
  static newMessage = {};
  static minutes_to_expire_conv =
    process.env.CONVERSATION_ENDS_AFTER_MINUTES || 30;

  constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    let ws_url_param = document
      .getElementById("botcells-widget")
      .getAttribute("gateway-socket-url");
    if (store.state.core.widgetParameters.externalSocketBase) {
      BotSocket.config.ws_url =
        store.state.core.widgetParameters.externalSocketBase;
    } else if (ws_url_param) {
      BotSocket.config.ws_url = ws_url_param;
      // s
    } else {
      BotSocket.config.ws_url = process.env.VUE_APP_BASE_URL;
    }

    if (
      !BotSocket.config.ws_url.includes("wss://") &&
      !BotSocket.config.ws_url.includes("ws://")
    ) {
      if (BotSocket.config.env === "dev") {
        BotSocket.config.ws_url = "ws://" + BotSocket.config.ws_url;
      } else {
        BotSocket.config.ws_url = "wss://" + BotSocket.config.ws_url;
      }
    }
    BotSocket.bot_id =
      store.state.core.botId ||
      document.getElementById("botcells-widget").getAttribute("bot_id") ||
      urlParams.get("bot_id");
    store.dispatch("setBotId", BotSocket.bot_id);
    BotSocket.setBotcellsGuid();

    BotSocket.init();
  }

  static setBotcellsGuid() {
    // Retrieve the cookie identifier
    let botcellsGuid = this.getCookie("botcells_guid");

    // Generate a new GUID if the cookie does not exist
    if (!botcellsGuid) {
      botcellsGuid = this.generate_guid();
      this.setCookie("botcells_guid", botcellsGuid, 30);
    }
    BotSocket.guid = botcellsGuid;
    store.dispatch("setGuid", botcellsGuid);
    return botcellsGuid;
  }

  static async handle() {
    if (store.state.core.socket) {
      this.socket = store.state.core.socket;
    }
    // Handle Websocket connection
    if (this.getCookie(`botcells_guid`) !== null) {
      // Check if there's socket in store

      if (store.state.messages.startNew && !store.state.messages.initBlock) {
        if (this.getCookie(`conversation_active_${this.guid}`) === "1") {
          this.sendMessage({
            type: "start_over",
          });
        } else {
          this.sendMessage({
            type: "hello",
          });
        }
        store.dispatch("setStartNew", false);
      } else {
        BotSocket.sendMessage({
          type: "welcome_back",
        });

        if (store.state.messages.initBlock) {
          BotSocket.deliverMessage({
            postback: {
              payload: `menu:block_id:{${store.state.messages.initBlock.block}}`,
              title: store.state.messages.initBlock.buttonLabel,
              type: "postback",
            },
            text: `menu:block_id:{${store.state.messages.initBlock.block}}`,
            type: "message",
            user: BotSocket.guid,
            channel: "web",
            user_profile: this.current_user ? this.current_user : null,
            bot_id: BotSocket.bot_id,
          });

          store.dispatch("setInitBlock", null);
        }
      }
    } else {
      BotSocket.newConversation();
    }
  }

  static async init() {
    // Create WebSocket connection.
    BotSocket.socket = new WebSocket(
      `${this.config.ws_url}?guid=${BotSocket.guid}`
    );
    // console.log(this);
    BotSocket.socket.addEventListener("open", async (event) => {
      store.dispatch("setConnected", true);
      await store.dispatch("setSocket", BotSocket.socket);
      // this.socket = this;
    });

    BotSocket.socket.addEventListener("error", (event) => {
      store.dispatch("setConnected", false);
    });

    BotSocket.socket.addEventListener("close", (event) => {
      store.dispatch("setConnected", false);
      if (this.reconnect_count < this.config.max_reconnect) {
        this.reconnect_count++;

        setTimeout(() => {
          this.init();
        }, this.config.reconnect_timeout);
      } else {
      }
    });

    // Listen for messages

    BotSocket.socket.addEventListener("message", (event) => {
      BotSocket.setCookieByMinutes(
        `conversation_active_${BotSocket.guid}`,
        "1",
        BotSocket.minutes_to_expire_conv
      );
      BotSocket.newMessage = {};
      let message = JSON.parse(event.data);
      try {
        BotSocket.newMessage = message;
      } catch (err) {
        BotSocket.newMessage = {
          slug: "text",
          text: message.text,
        };
      }

      if (BotSocket.newMessage.history) {
        let historyMessage = {};
        for (let i = 0; i < BotSocket.newMessage.history.length; i++) {
          historyMessage = {};
          if (
            BotSocket.newMessage.history[i].sender.sender_type === "BOT" ||
            BotSocket.newMessage.history[i].sender.sender_type === "AGENT"
          ) {
            historyMessage = { ...BotSocket.newMessage.history[i] };
            historyMessage.type = "bot";
          } else {
            historyMessage = { ...BotSocket.newMessage.history[i] };
            historyMessage.payload = historyMessage.text;
            if (!BotSocket.newMessage.history[i].slug) {
              historyMessage.slug = "text";
            }
            historyMessage.type = "user";
          }

          historyMessage.lastMessageGroup = true;
          if (
            !["hello", "welcome_back", "set_attributes"].includes(
              BotSocket.newMessage.history[i].type
            )
          ) {
            store.dispatch("pushMessage", historyMessage);
          }
        }
        setTimeout(() => {
          store.dispatch("setInputDisabled", false);
        }, 1000);
      } else {
        BotSocket.newMessage.type = "bot";
        BotSocket.newMessage.lastMessageGroup = true;
        store.dispatch("pushMessage", BotSocket.newMessage);
        if (message.slug === "typing") {
          messages.playSound(store.state.core.widgetParameters.typingSound);
        } else {
          messages.playSound(store.state.core.widgetParameters.messageSound);
        }
      }

      messages.browserNotification();
    });
  }

  static newConversation() {
    let connectEvent = "hello";

    if (BotSocket.options.enable_history) {
      BotSocket.getHistory();
    }

    // Connection opened
    BotSocket.socket.addEventListener("open", (event) => {
      let attributes = [];
      let customMessage = "";
      attributes = store.state.core.attributes.concat(
        store.state.core.externalAttributes
      );
      //pushing widget lang
      attributes.push({
        attribute: "lang",
        value: store.state.core.locale.substring(0, 2),
      });

      if (store.state.core.widgetParameters.allowParametersFromURL) {
        let URLAttributes = [];
        URLAttributes = this.getAttributesFromURL();
        attributes = attributes.concat(URLAttributes);

        let customMessageObject = URLAttributes.find((param) => {
          return param.attribute === "type";
        });
        if (customMessageObject) {
          customMessage = customMessageObject.value;
        }
      }

      if (store.state.messages.initBlock) {
        connectEvent = "";
      }

      if (BotSocket.reconnect_count === 0) {
        BotSocket.deliverMessage({
          type: connectEvent,
          user: BotSocket.guid,
          channel: "web",
          user_profile: BotSocket.current_user ? BotSocket.current_user : null,
          bot_id: BotSocket.bot_id,
          attributes: attributes,
        });
      }

      if (store.state.messages.initBlock) {
        BotSocket.deliverMessage({
          postback: {
            payload: `menu:block_id:{${store.state.messages.initBlock.block}}`,
            title: store.state.messages.initBlock.buttonLabel,
            type: "postback",
          },
          text: `menu:block_id:{${store.state.messages.initBlock.block}}`,
          type: "message",
          user: BotSocket.guid,
          channel: "web",
          user_profile: BotSocket.current_user ? BotSocket.current_user : null,
          bot_id: BotSocket.bot_id,
        });
        store.dispatch("setInitBlock", null);
      }

      setTimeout(() => {
        BotSocket.reconnect_count = 0;
      }, 5000);

      if (customMessage) {
        setTimeout(() => {
          BotSocket.deliverMessage({
            slug: "text",
            text: customMessage,
            type: "message",
            user: BotSocket.guid,
            channel: "web",
            user_profile: BotSocket.current_user
              ? BotSocket.current_user
              : null,
            bot_id: BotSocket.bot_id,
          });
        }, 2000);
      }
    });
  }

  static sendMessage(message) {
    BotSocket.deliverMessage({
      ...message,
      user: BotSocket.guid,
      channel: "web",
      bot_id: BotSocket.bot_id,
    });
  }

  // sendAttributes() {
  //   let attributes =store.state.core.attributes.concat(store.state.core.externalAttributes)
  //
  //   if (store.state.core.widgetParameters.allowParametersFromURL) {
  //     let URLAttributes = this.getAttributesFromURL()
  //     attributes = attributes.concat(URLAttributes)
  //   }
  //
  //   this.deliverMessage({
  //     type: 'set_attributes',
  //     attributes: attributes,
  //     user: BotSocket.guid,
  //     channel: 'web',
  //     bot_id: BotSocket.bot_id
  //   })
  // }

  getAttributesFromURL() {
    let query = this.getQueryParams();
    let parameters = [];
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        parameters.push({
          attribute: key,
          value: query[key],
        });
      }
    }
    return parameters;
  }

  getQueryParams() {
    let search = location.search.substring(1);
    if (search) {
      return JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
    } else {
      return {};
    }
  }

  static deliverMessage(message) {
    BotSocket.socket.send(JSON.stringify(message));
  }

  static getHistory() {}

  static s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  static generate_guid() {
    let userId = document
      .getElementById("botcells-widget")
      .getAttribute("user_id");
    if (userId) {
      return userId;
    } else {
      return (
        this.s4() +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        this.s4() +
        this.s4()
      );
    }
  }

  static setCookie(key, value, ttl) {
    key = `${key}_${BotSocket.bot_id}`;
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl * 24 * 60 * 60 * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  static setCookieByMinutes(key, value, ttl) {
    key = `${key}_${BotSocket.bot_id}`;
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl * 60 * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  static getCookie(key) {
    key = `${key}_${BotSocket.bot_id}`;
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
}
