<template>
  <div class="snackbar">
    <div class="d-flex align-center justify-space-around">
      <p><slot /></p>
      <div class="typing-indicator">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "snackbar",
};
</script>

<style scoped lang="scss">
.snackbar {
  position: absolute;
  bottom: 100%;
  width: 100%;
  left: 0;
  padding: 12px 30px;
  background: #fff9ec;
  p {
    font-size: 12px !important;
    color: black;
  }
  .typing-indicator {
    span {
      height: 5px;
      width: 5px;
      float: left;
      margin: 0 1px;
      background-color: var(--primaryColor);
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation: 1s blink infinite ($i * 0.3333s);
        }
      }
    }
  }
}
</style>
