export const ocrSteps = {
    state: {
      stepsData: {},
      currentStep: null,
      vlens: {
        transactionId: null,
      },
      uploadedStepsData: {
        national_id: null,
        car_license: null,
      },
    },
    mutations: {
      SET_STEPS_DATA(state, stepsData) {
        state.stepsData = stepsData;
      },
      SET_TRANSACTION_ID(state, id) {
        state.vlens.transactionId = id;
      },
      SET_NATIONAL_ID_DATA(state, payload) {
        state.uploadedStepsData["national_id"] = payload;
      },
      SET_CAR_LICENSE_DATA(state, payload) {
        state.uploadedStepsData["car_license"] = payload;
      },
      SET_CURRENT_STEP(state, step) {
        state.currentStep = step;
      },
    },
    actions: {
      setOcrStepsData({ commit }, stepsData) {
        commit("SET_STEPS_DATA", stepsData);
      },
      setVlensTransactionId({ commit }, id) {
        commit("SET_TRANSACTION_ID", id);
      },
      setNationalIdData({ commit }, payload) {
        commit("SET_NATIONAL_ID_DATA", payload);
      },
      setCarLicenseData({ commit }, payload) {
        commit("SET_CAR_LICENSE_DATA", payload);
      },
      setCurrentStep({ commit }, step) {
        commit("SET_CURRENT_STEP", step);
      },
    },
  };
  