<template>
  <div>
    <div
      class="botcells-agent-chat-image"
      v-if="sender && sender.sender_type === 'AGENT'"
    >
      <img
        :src="
          this.$store.state.core.widgetParameters.agentDefaultAvatar ||
          'https://gamma-api.botcells.com/uploads/2020-08-16/1597590733.png'
        "
        alt=""
      />
    </div>
    <div class="botcells-bot-chat-image" v-else>
      <img
        :src="
          sender.avatar || this.$store.state.core.widgetParameters.botAvatar
        "
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "chatImage",
  props: {
    sender: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.botcells-bot-chat-image {
  overflow: hidden;
  background: var(--primaryColor);
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 36px;
  height: 36px;
  /* padding: 8px; */
  border-radius: 100%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    width: 22px;
    height: 22px;
  }
}

.botcells-agent-chat-image {
  position: absolute;
  bottom: 15px;
  left: 0;

  /* padding: 8px; */
  border-radius: 100%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 36px;
    height: 36px;
  }
}

.botcells-hero {
  .botcells-bot-chat-image,
  .botcells-agent-chat-image {
    bottom: 0px;
  }
}

.botcells-typing-card,
.botcells-agent-chat-image {
  .botcells-bot-chat-image {
    bottom: 0px;
  }
}
.botcells-trivia {
  .botcells-bot-chat-image,
  .botcells-agent-chat-image {
    bottom: 60px;
  }
}
</style>
