<template>
  <div class="botcells-intro-form-card">
    <div v-if="$store.state.core.introDataLoader">
      <v-skeleton-loader
        v-bind="attrs"
        v-for="i in 3"
        :key="i"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <h4 v-if="$store.state.core.startForm">
        {{ $store.state.core.startForm.label }}
      </h4>
      <v-form ref="userAttributes" @submit.prevent="startSession">
        <div v-if="!this.$store.state.core.widgetParameters.hideForms">
          <div v-if="$store.state.core.startForm">
            <div
              v-for="(field, index) in $store.state.core.startForm.inputs"
              :key="`${formFields.key}${index}`"
            >
              <component
                :is="getFileName(field.type)"
                :field="field"
                :form-data="formData"
                :index="index"
              />
            </div>
          </div>
        </div>
      </v-form>
      <div class="">
        <v-btn
          class="mt-3"
          @click="startSession"
          rounded
          height="40"
          width="200"
          color="primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g
              id="prefix__noun_send_2498628"
              transform="translate(-151.255 -154.027)"
            >
              <g
                id="prefix__Group_3"
                data-name="Group 3"
                transform="translate(151.255 154.027)"
              >
                <path
                  id="prefix__Path_4"
                  d="M166.814 161.388l-14.419-7.3c-.647-.208-1.265.148-1.118.891l2.06 5.581 8.828 1.455-8.828 1.484-2.06 5.581c-.147.742.471 1.1 1.118.891l14.419-7.333a.661.661 0 0 0 0-1.25z"
                  data-name="Path 4"
                  transform="translate(-151.255 -154.027)"
                  style="fill: #fff; fill-rule: evenodd"
                />
              </g>
            </g>
          </svg>

          <span class="ml-2 mr-2 botcells-chat-start">
            {{
              $store.state.core.widgetParameters.startButtonLabel ||
              "Start Conversation"
            }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import bDate from "./inputs/bDate";
import bRadio from "./inputs/bRadio";
import bSelect from "./inputs/bSelect";
import bTextarea from "./inputs/bTextarea";
import bTextfield from "./inputs/bTextfield";
import bTime from "./inputs/bTime";
export default {
  name: "intro-forms",
  components: {
    bSelect,
    bTextfield,
    bTextarea,
    bRadio,
    bTime,
    bDate,
  },
  data() {
    return {
      formTitle: "Please enter your info ",
      formFields: [
        {
          type: "textfield",
          label: "Email",
          key: "email",
          required: true,
          regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          errorMessage: "Please enter valid email",
        },
        {
          type: "select",
          options: ["option 1", "option 2", "option 3"],
          multiple: true,
          label: "Select",
          key: "option",
          required: true,
          regex: "",
          errorMessage: "",
        },
        {
          type: "textarea",
          label: "Description",
          key: "desc",
          required: false,
          regex: "",
          errorMessage: "",
        },
        {
          type: "radio",
          label: "type",
          options: [
            "hello botcells how are u",
            "hello botcells option2",
            "hello botcells option 3",
          ],
          key: "type",
          required: false,
          regex: "",
          errorMessage: "",
        },
        {
          type: "time",
          label: "Time",
          key: "time",
          required: false,
          regex: "",
          errorMessage: "",
        },
        {
          type: "date",
          label: "Date",
          key: "date",
          required: false,
          regex: "",
          errorMessage: "",
        },
      ],
      formData: {},
      types: ["select", "textfield", "textarea", "radio", "time", "date"],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },
  methods: {
    startSession() {
      if (
        this.$refs.userAttributes.validate() ||
        this.$store.state.core.widgetParameters.hideForms
      ) {
        let attributes = [];
        for (let key in this.formData) {
          if (this.formData.hasOwnProperty(key)) {
            attributes.push({
              attribute: key,
              value: this.formData[key],
            });
          }
        }
        attributes.push({
          attribute: "user_type",
          value: "guest",
        });
        this.$store.dispatch("setAttributes", attributes);
        this.$emit("start-chat");
      }
    },
    getQueryParams() {
      let search = location.search.substring(1);
      if (search) {
        return JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
      } else {
        return {};
      }
    },
    getFileName(type) {
      if (this.types.includes(type)) {
        return `b-${type}`;
      } else {
        return "div";
      }
    },
  },
};
</script>

<style scoped>
.botcells-chat-start {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 300;
  letter-spacing: normal;
}
</style>
