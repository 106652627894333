<template>
  <span
    class="botcells-svg botcells-chart-send-button botcells-chat-button ms-2"
    @click="$emit('click', $event)"
  >
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M12,2A3,3 0 0,1 15,5V11A3,3 0 0,1 12,14A3,3 0 0,1 9,11V5A3,3 0 0,1 12,2M19,11C19,14.53 16.39,17.44 13,17.93V21H11V17.93C7.61,17.44 5,14.53 5,11H7A5,5 0 0,0 12,16A5,5 0 0,0 17,11H19Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "recordButton",
};
</script>

<style lang="css" scoped>
svg {
  fill: var(--primaryColor) !important;
  text-align: center;
}
</style>
