var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text botcells-conversation"},[_c('div',{staticClass:"d-flex align-end",class:_vm.getAlignment()},[(_vm.con.type === 'bot' && _vm.lastMessageGroup)?_c('user-chat-image',{attrs:{"sender":_vm.con.sender}}):_vm._e(),_c('div',{staticClass:"botcells-gallery"},[_c('carousel',{attrs:{"paginationEnabled":false,"perPage":1.5,"scrollPerPage":false,"navigationEnabled":true,"navigationNextLabel":"<svg xmlns='http://www.w3.org/2000/svg' width='8.344' height='13.203' viewBox='0 0 8.344 13.203'>\n    <g id='prefix__noun_right_1786453' transform='translate(-30.656 -19.391)'>\n        <g id='prefix__Group_14' data-name='Group 14' transform='translate(30.656 19.391)'>\n            <path id='prefix__Path_5' d='M32.152 971.753l-1.5 1.557 5.23 5.048-5.23 5.041 1.5 1.557 6.039-5.823.809-.775-.809-.782z' data-name='Path 5' transform='translate(-30.656 -971.753)' style='fill:#fff'/>\n        </g>\n    </g>\n</svg>\n","navigationPrevLabel":"<svg xmlns='http://www.w3.org/2000/svg' id='prefix__noun_right_1786453' width='8.344' height='13.203' viewBox='0 0 8.344 13.203'>\n    <g id='prefix__Group_14' data-name='Group 14'>\n        <path id='prefix__Path_5' d='M37.5 971.753l1.5 1.557-5.23 5.048L39 983.4l-1.5 1.557-6.039-5.823-.809-.775.809-.782z' data-name='Path 5' transform='translate(-30.656 -971.753)' style='fill:#fff'/>\n    </g>\n</svg>\n"}},_vm._l((_vm.con.data),function(slide,index){return _c('slide',{key:index,attrs:{"data-index":index}},[_c('div',{staticClass:"botcells-gallery-item"},[(slide.media_type === 'video')?_c('div',[(slide.url.includes('youtube'))?_c('iframe',{attrs:{"width":"100%","src":`//www.youtube.com/embed/${_vm.common.get_yt_iframe_from_url(
                    slide.url
                  )}`,"frameborder":"0","allowfullscreen":""}}):_c('video',{attrs:{"width":"300","controls":""}},[_c('source',{attrs:{"src":slide.url,"type":"video/mp4"}})])]):_c('img',{attrs:{"src":slide.url,"alt":""}}),_c('h3',{class:_vm.common.getTextAlignment(slide.header)},[_vm._v(" "+_vm._s(slide.header)+" ")]),_c('p',{class:_vm.common.getTextAlignment(slide.desc)},[_vm._v(" "+_vm._s(slide.desc)+" ")]),_c('div',{staticClass:"botcells-gallery-buttons-list"},_vm._l((slide.actions),function(btn,btnIndex){return _c('div',{key:btnIndex,staticClass:"mt-3"},[(btn.type === 'call')?_c('a',{class:{ 'button-disabled': _vm.inputDisabled },attrs:{"href":`tel:${btn.value}`,"target":"_blank"},on:{"click":function($event){return _vm.selectCard(index)}}},[_c('b-btn',[_vm._v(" "+_vm._s(btn.title)+" ")])],1):_vm._e(),(btn.type === 'openUrl')?_c('a',{class:{ 'button-disabled': _vm.inputDisabled },attrs:{"href":btn.value,"target":"_blank"},on:{"click":function($event){return _vm.selectCard(index)}}},[_c('b-btn',[_vm._v(" "+_vm._s(btn.title)+" ")])],1):_vm._e(),_c('div',{class:{
                      'button-disabled':
                        _vm.inputDisabled ||
                        (_vm.clicked.includes(index) &&
                          _vm.$store.state.core.widgetParameters
                            .buttonsSingleClick),
                    },on:{"click":function($event){return _vm.postBack(btn, index)}}},[(btn.type === 'postBack')?_c('b-btn',[_vm._v(" "+_vm._s(btn.title)+" ")]):_vm._e()],1),_c('div',{class:{ 'button-disabled': _vm.inputDisabled },on:{"click":function($event){return _vm.callEvent(btn.value)}}},[(btn.type === 'event')?_c('b-btn',[_vm._v(" "+_vm._s(btn.title)+" ")]):_vm._e()],1)])}),0)])])}),1)],1)],1),(_vm.lastMessageGroup)?_c('div',{staticClass:"chat-date d-flex",class:_vm.getAlignment()},[_vm._v(" "+_vm._s(_vm.common.formatDate(_vm.con.date))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }