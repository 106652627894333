<template>
  <div class="botcells-intro-form-card mt-3 mb-3">
    <h4>{{ $t("Continue Conversation") }}</h4>
    <div
      class="botcells-continue d-flex align-center justify-space-between mt-3"
      @click="continueConversation"
    >
      <div class="d-flex align-center">
        <div class="botcells-agent-avatar d-flex">
          <img
            :src="this.$store.state.core.widgetParameters.botAvatar"
            height="35"
            alt=""
          />
        </div>
        <div class="ml-2 mr-2">
          <h5>{{ this.$store.state.core.widgetParameters.botName }}</h5>
          <div class="botcells-agent-status d-flex align-center">
            <div class="botcells-online-indecator">
              <span></span>
            </div>
            <p>{{ lastMessageDate }}</p>
          </div>
        </div>
      </div>

      <span class="mdi mdi-chevron-right"></span>
    </div>
  </div>
</template>

<script>
import common from "../utils/common";
export default {
  name: "continueConversation",
  data() {
    return {
      lastMessageDate: "",
    };
  },
  mounted() {
    let date = localStorage.getItem("lastMessageDate");
    this.lastMessageDate = common.timeAgo(date);
    setInterval(() => {
      this.lastMessageDate = common.timeAgo(date);
    }, 12000);
  },
  methods: {
    continueConversation() {
      this.$emit("continueConversation");
    },
  },
};
</script>

<style scoped lang="scss">
.botcells-continue {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 10px;
  span {
    color: var(--primaryColor);
    font-size: 20px;
  }
  &:hover {
    background: #eee;
  }
  .botcells-agent-avatar {
    overflow: hidden;
    border-radius: 100%;
    padding: 5px;
    img {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
