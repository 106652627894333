<template>
  <div>
    <label for="">{{field.label}}</label>
    <v-radio-group v-model="formData[field.key]" :mandatory="false" >
      <v-radio v-for="option in field.options" :key="option" :label="option" :value="option"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
  export default {
    name: "b-text-field",
    props: {
      field: {
        type: Object,
        default: ()=> {
          return {}
        }
      },
      formData: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data () {
      return {

      }
    },
    computed: {
      rules() {
        return [
          this.field.required? v => !!v || this.field.errorMessage : true,
          this.field.regex ? v => this.field.regex.test(v) || this.field.errorMessage : true
        ]
      }
    }
  }
</script>

<style scoped>

</style>
