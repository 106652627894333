var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"botcells--widget"},[_c('div',{staticClass:"botcells-modal"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.sessionActive)?_c('div',{key:"1",staticClass:"botcells-modal-wrapper"},[_c('div',{staticClass:"botcells-modal-head"},[_c('div',{staticClass:"botcells-agents-list"},[_c('div',{staticClass:"align-center d-flex"},[(
                  !_vm.widgetConfigurations.hideFaqs ||
                  !_vm.widgetConfigurations.hideForms ||
                  _vm.widgetConfigurations.displayIntroButtons
                )?_c('div',{staticClass:"botcells-back-button",on:{"click":_vm.backToIntro}},[_c('span',{staticClass:"mdi mdi-chevron-left"})]):_vm._e(),(_vm.widgetConfigurations.avatarType === 'square')?_c('div',{staticClass:"botcells-agent-avatar-square ml-2"},[_c('img',{attrs:{"src":_vm.widgetConfigurations.logo,"alt":""}})]):_c('div',{staticClass:"botcells-agent-avatar d-flex ml-2"},[_c('img',{attrs:{"src":_vm.widgetConfigurations.botAvatar,"alt":""}})]),_c('div',{staticClass:"ml-2 mr-2"},[_c('h5',[_vm._v(_vm._s(_vm.widgetConfigurations.botName))]),_c('div',{staticClass:"botcells-agent-status d-flex align-center"},[_c('div',{staticClass:"botcells-online-indecator",class:{ 'botcells-online': _vm.connected }},[_c('span')]),(_vm.connected)?_c('p',[_vm._v(_vm._s(_vm.$t("online")))]):_c('p',[_vm._v(_vm._s(_vm.$t("back in minutes")))])])]),_c('div',{staticClass:"botcells-close-mobile",class:{
                  'botcells-close-desktop':
                    _vm.widgetConfigurations.hideOpenButton,
                },on:{"click":_vm.openModal}},[_c('img',{staticClass:"botcells-modal-close-btn",attrs:{"src":_vm.widgetConfigurations.closeBubbleImage,"alt":""}})])])])]),_c('widget-body',{attrs:{"session-active":_vm.sessionActive,"modal-active":_vm.modalActive,"on-focus":_vm.modalOnFocus}})],1):(
          !_vm.widgetConfigurations.hideFaqs ||
          !_vm.widgetConfigurations.hideForms ||
          _vm.widgetConfigurations.displayIntroButtons
        )?_c('div',{key:"2",staticClass:"botcells-intro"},[_c('div',{staticClass:"botcells-intro-header"},[_c('img',{attrs:{"src":_vm.widgetConfigurations.logo,"alt":""}}),_c('h3',{staticClass:"botcells-intro-title"},[_vm._v(" "+_vm._s(_vm.widgetConfigurations.header)+" ")]),_c('p',[_vm._v(_vm._s(_vm.widgetConfigurations.subHeader))])]),_c('div',{staticClass:"botcells-close-mobile",on:{"click":_vm.openModal}},[_c('img',{staticClass:"botcells-modal-close-btn",attrs:{"src":_vm.widgetConfigurations.closeBubbleImage,"alt":""}})]),(_vm.$store.state.core.botId)?_c('div',{staticClass:"botcells-intro-forms"},[_c('div',{staticClass:"intro-wrapper"},[(
                _vm.common.getCookie('botcells_guid') &&
                _vm.common.getCookie(`conversation_active_${_vm.guid}`) == '1'
              )?_c('continue-conversation',{on:{"continueConversation":_vm.continueSession}}):_vm._e(),_c('intro-buttons',{on:{"start-chat":_vm.startSession}}),_c('intro-form',{on:{"start-chat":_vm.startSession}}),(!_vm.widgetConfigurations.hideFaqs)?_c('faqs'):_vm._e(),_c('social-links')],1)]):_vm._e()]):_vm._e()])],1),(
      !_vm.botcellsStarted &&
      !_vm.widgetConfigurations.hideOpenButton &&
      !_vm.channelsButtonsActive
    )?_c('div',[(_vm.widgetConfigurations.offLineMessageAppearance)?_c('offline-message'):_vm._e()],1):_vm._e(),_c('open-button',{on:{"openModal":_vm.openModal,"activeChannels":function($event){_vm.channelsButtonsActive = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }