export default [
  {
    name: "en",
    code: "en_EN",
  },
  {
    name: "ar",
    code: "ar_AR",
  },
];
