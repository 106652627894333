import store from "../store";
export default class messages {
  static checkLastMessageGroup(index) {
    if (
      store.state.messages.conversationList[index + 1] &&
      store.state.messages.conversationList[index + 1].type ===
        store.state.messages.conversationList[index].type
    ) {
      return false;
    } else {
      return true;
    }
  }

  static playSound(
    filename = "https://cdn.botcells.com/2024-08-22/1724366568044.mp3"
  ) {
    var mp3Source = '<source src="' + filename + '" type="audio/mpeg">';
    var oggSource = '<source src="' + filename + '" type="audio/ogg">';
    var embedSource =
      '<embed hidden="true" autostart="true" loop="false" src="' +
      filename +
      '">';
    document.getElementById("sound").innerHTML =
      '<audio autoplay="autoplay">' +
      mp3Source +
      oggSource +
      embedSource +
      "</audio>";
  }

  static browserNotification(message) {
    // if (Notification.permission !== 'granted')
    //   Notification.requestPermission();
    // else {
    //   let notification = new Notification('Botcells', {
    //     icon: 'https://botcells.com/wp-content/uploads/2020/06/102649589_192182382026517_5295529162797000898_n.gif',
    //     body: 'BOTCELLS sent a message',
    //   });
    //   notification.onclick = function() {
    //     window.open('http://stackoverflow.com/a/13328397/1269037');
    //   };
    // }
  }
}
