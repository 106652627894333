<template>
  <div>
    <div class="booking-steps" :style="clientStyle">
      <div class="step-actions">
        <v-icon @click="step--" color="black" v-show="step != 1"
          >mdi-arrow-left</v-icon
        >
        <v-spacer></v-spacer>
        <v-icon @click="cancelBooking" color="black">mdi-close</v-icon>
      </div>
      <v-stepper
        v-model="step"
        alt-labels
        elevation="0"
        v-if="this.$store.state.bookingSteps.stepsData.step1"
        v-ripple="false"
      >
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" :editable="step > 1">
            <div class="step-img">
              <img :src="cardData.step1.icon" width="25" alt="" />
            </div>
            <p class="mb-0 text-center d-block">{{ cardData.step1.name }}</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2" :editable="step > 2">
            <div class="step-img">
              <img :src="cardData.step2.icon" width="25" alt="" />
            </div>
            <p class="mb-0 text-center d-block">{{ cardData.step2.name }}</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            <div class="step-img">
              <img :src="cardData.step3.icon" width="25" alt="" />
            </div>
            <p class="mb-0 text-center">{{ cardData.step3.name }}</p>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <step1 @nextStep="step++" @cancel="cancelBooking" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <step2 @nextStep="step++" v-if="step > 1" @cancel="cancelBooking" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <step3
              v-if="step > 2"
              @submitBooking="submitBooking"
              @cancel="cancelBooking"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import step1 from "./step1/step1";
import step2 from "./step2/step2";
import step3 from "./step3/step3";
import locale from "../../../configs/locale";
export default {
  name: "BookingSteps",
  components: {
    step1,
    step2,
    step3,
  },
  data() {
    return {
      step: 1,
      expired: false,
    };
  },
  props: {
    cardData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    this.$store.dispatch("setStepsData", this.cardData);
    let lang = locale.find((item) => {
      return item.name === this.cardData.lang;
    });
    this.$store.state.core.widgetParameters.lang = lang.code;
    this.$store.state.core.locale = lang.code;
    this.$i18n.locale = lang.code;
  },
  methods: {
    async getCardData() {},
    async submitBooking() {
      try {
        this.$emit(
          "postBack",
          this.$store.state.bookingSteps.selectedStepsData
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    async cancelBooking() {
      this.$emit("postCustom", {
        type: "message",
        text: "cancel_all_dialogs",
        cancelRedirectBlock: this.cardData.cancelRedirectBlock,
      });
    },
  },

  computed: {
    clientStyle() {
      return {
        "--secondary-color":
          this.$store.state.core.widgetParameters.introButtonsColor,
      };
    },
  },
};
</script>

<style lang="scss">
::v-deep .v-stepper__header .v-ripple__container {
  display: none !important;
}

.booking-steps {
  .container {
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media (max-width: 800px) {
  .booking-steps {
    .container {
    }
  }
}

.booking-steps {
  position: relative;
  h2 {
    margin-bottom: 15px;
    margin-right: 50px;
  }
  .logo {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 47px;
    }
  }
}

@media (min-width: 1200px) {
  .booking-steps {
    .logo {
      position: absolute;
      z-index: 1000;
      max-width: 300px;
      top: 15px;
      left: 0;
    }
  }
  .rtl {
    .booking-steps {
      .logo {
        right: 0;
        left: auto;
      }
    }
  }
}
.step-actions {
  padding: 10px 35px 0px 35px;
  position: absolute;
  z-index: 10;
  width: 94%;
  justify-content: space-between;
  display: flex;
  top: 30px;
  .v-icon {
  }
}

@media (max-width: 800px) {
  .step-actions {
    position: static;
    padding: 15px;
  }
}

.v-stepper__header {
  box-shadow: none !important;
  max-width: 775px;
  margin: auto;
  .v-stepper__step__step {
    display: none;
  }
  .v-divider {
    border-color: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
    border-width: 1px;
    border-radius: 4px;
    margin: 52px -160px 0 !important;
  }
  .v-stepper__label {
    .step-img {
      display: flex;
      width: 50px;
      margin: auto;
      height: 50px;
      border: 2px solid #c7c7c7;
      border-radius: 100%;
      align-content: center;
      background: white;
      z-index: 1000;
      img {
        margin: auto;
        width: 25px;
        filter: brightness(0) invert(0.8);
      }
    }
    p {
      color: #c7c7c7;
      font-size: 14px;
      margin-top: 8px;
      width: 100%;
    }
  }
  .v-stepper__step--active {
    .v-stepper__label {
      .step-img {
        border: 2px solid var(--primaryColor);
        background: white;
        filter: none;
        img {
          filter: none;
        }
      }
      p {
        color: var(--primaryColor);
      }
    }
  }

  .v-stepper__step--complete {
    .v-stepper__label {
      .step-img {
        border: 2px solid var(--primaryColor);
        background: var(--primaryColor);
        filter: none;
        img {
          filter: brightness(0) invert(1) !important;
        }
      }
      p {
        color: var(--primaryColor);
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

.v-stepper__content {
  padding: 0;
}

.step-nav {
  height: 100px;
  background: white;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.08);
  .v-btn {
    width: 250px;
    height: 50px !important;
    border-radius: 10px;
    text-transform: capitalize;
    font-size: 17px;
    margin: 0 10px;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 800px) {
  .step-nav {
    height: 80px;
    .v-btn {
      width: 100px;
      height: 40px !important;
      font-size: 15px;
    }
  }
}

.stepper-padding {
  padding-bottom: 125px;
}

.v-stepper__wrapper {
  border-top: 3px solid #efefef;
  padding-top: 15px;
}

.v-stepper {
  box-shadow: none !important;
  .v-stepper__content {
    padding: 0 !important;
  }
}

.v-stepper--alt-labels .v-stepper__step {
  flex-basis: auto !important;
}

.theme--light.v-stepper .v-stepper__step--editable:hover {
  background: transparent !important;
}
</style>
