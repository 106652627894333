<template>
  <div>
    <div class="ocr-steps" :style="clientStyle">
      <!-- <div class="d-flex justify-center">
        <div>
          <h3 v-if="title">{{ $t(title) }}</h3>
          <p class="grey--text" style="font-size: 13px">
            {{ description }}
          </p>
        </div>
      </div> -->
      <div class="logo d-flex justify-space-between">
        <img :src="logo" alt="" />
        <div>
          <h3 v-if="title">{{ $t(title) }}</h3>
          <p class="grey--text" style="font-size: 13px">
            {{ description }}
          </p>
        </div>
      </div>

      <!-- <div class="step-actions"> -->
      <v-icon @click="cancelDialog" color="black">mdi-close</v-icon>
      <!-- </div> -->
      <v-stepper
        v-if="currentStep"
        v-model="currentStep.id"
        alt-labels
        elevation="0"
        v-ripple="false"
      >
        <v-stepper-header>
          <template v-for="(s, index) in cardData.steps">
            <v-stepper-step
              :key="`${index}-step`"
              :complete="s.id != currentStep.id"
              :step="s.id"
              :editable="false"
            >
              <p
                class="mb-0 text-center d-block"
                v-if="s.name == 'national-id'"
              >
                {{ $t("NationalId") }}
              </p>
              <p
                class="mb-0 text-center d-block"
                v-if="s.name == 'car-license'"
              >
                {{ $t("CarLicense") }}
              </p>
            </v-stepper-step>

            <v-divider :key="s.id"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <step1
              @nextStep="setCurrentStep"
              @submitData="submitData"
              @cancel="cancelDialog"
            ></step1>
          </v-stepper-content>

          <v-stepper-content step="2">
            <step2
              @nextStep="setCurrentStep"
              @submitData="submitData"
              @cancel="cancelDialog"
            ></step2>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import locale from "../../../configs/locale";
import step1 from "./step1/step1";
import step2 from "./step2/step2";
export default {
  name: "OcrSteps",
  components: {
    step1,
    step2,
  },
  data() {
    return {
      step: 1,
      expired: false,
    };
  },
  props: {
    cardData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    this.$store.dispatch("setOcrStepsData", this.cardData);
    let lang = locale.find((item) => {
      return item.name === this.cardData.lang;
    });
    this.$store.state.core.widgetParameters.lang = lang.code;
    this.$store.state.core.locale = lang.code;
    this.$i18n.locale = lang.code;

    this.$store.dispatch("setCurrentStep", this.cardData.steps[0]);
  },
  methods: {
    async getCardData() {},
    async submitData() {
      try {
        this.$emit("postBack", this.$store.state.ocrSteps.uploadedStepsData);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    async cancelDialog() {
      this.$emit("postCustom", {
        type: "message",
        text: "cancel_all_dialogs",
        cancelRedirectBlock: this.cardData.cancelRedirectBlock,
      });
    },
    setCurrentStep(step) {
      this.$store.dispatch("setCurrentStep", step);
    },
  },

  computed: {
    clientStyle() {
      return {
        "--secondary-color":
          this.$store.state.core.widgetParameters.introButtonsColor,
      };
    },
    logo() {
      return this.$store.state.core.widgetParameters.logo;
    },
    title() {
      let title = this.$store.state.ocrSteps.stepsData.extensionTitle;
      if (title) {
        return title.toLowerCase();
      }
      return null;
    },
    description() {
      let description =
        this.$store.state.ocrSteps.stepsData.extensionDescription;
      if (description) {
        return description;
      }
      return null;
    },
    currentStep() {
      return this.$store.state.ocrSteps.currentStep;
    },
  },
};
</script>

<style lang="scss">
::v-deep .v-stepper__header .v-ripple__container {
  display: none !important;
}

.ocr-steps {
  .container {
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media (max-width: 800px) {
  .ocr-steps {
    .container {
    }
  }
}

.ocr-steps {
  position: relative;
  h2 {
    margin-bottom: 15px;
    margin-right: 50px;
  }
  .logo {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 47px;
    }
  }
}

@media (min-width: 1200px) {
  .ocr-steps {
    .logo {
      margin: auto;
      z-index: 1000;
      max-width: 300px;
      top: 15px;
      left: 0;
    }
  }
  .rtl {
    .ocr-steps {
      .logo {
        right: 0;
        left: auto;
      }
    }
  }
}
.step-actions {
  padding: 10px 35px 0px 35px;
  position: absolute;
  z-index: 10;
  width: 94%;
  justify-content: space-between;
  display: flex;
  top: 30px;
  .v-icon {
  }
}

@media (max-width: 800px) {
  .step-actions {
    position: static;
    padding: 15px;
  }
}

.v-stepper__header {
  box-shadow: none !important;
  max-width: 775px;
  margin: auto;
  .v-stepper__step__step {
    display: none;
  }
  .v-divider {
    border-color: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
    border-width: 1px;
    border-radius: 4px;
    margin: 52px -160px 0 !important;
  }
  .v-stepper__label {
    .step-img {
      display: flex;
      width: 50px;
      margin: auto;
      height: 50px;
      border: 2px solid #c7c7c7;
      border-radius: 100%;
      align-content: center;
      background: white;
      z-index: 1000;
      img {
        margin: auto;
        width: 25px;
        filter: brightness(0) invert(0.8);
      }
    }
    p {
      color: #c7c7c7;
      font-size: 14px;
      margin-top: 8px;
      width: 100%;
    }
  }
  .v-stepper__step--active {
    .v-stepper__label {
      .step-img {
        border: 2px solid var(--primaryColor);
        background: white;
        filter: none;
        img {
          filter: none;
        }
      }
      p {
        color: var(--primaryColor);
      }
    }
  }

  .v-stepper__step--complete {
    .v-stepper__label {
      .step-img {
        border: 2px solid var(--primaryColor);
        background: var(--primaryColor);
        filter: none;
        img {
          filter: brightness(0) invert(1) !important;
        }
      }
      p {
        color: var(--primaryColor);
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

.v-stepper__content {
  padding: 0;
}

.step-nav {
  height: 100px;
  background: white;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.08);
  .v-btn {
    width: 250px;
    height: 50px !important;
    border-radius: 10px;
    text-transform: capitalize;
    font-size: 17px;
    margin: 0 10px;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 800px) {
  .step-nav {
    height: 80px;
    .v-btn {
      width: 100px;
      height: 40px !important;
      font-size: 15px;
    }
  }
}

.stepper-padding {
  padding-bottom: 125px;
}

.v-stepper__wrapper {
  border-top: 3px solid #efefef;
  padding-top: 15px;
}

.v-stepper {
  box-shadow: none !important;
  .v-stepper__content {
    padding: 0 !important;
  }
}

.v-stepper--alt-labels .v-stepper__step {
  flex-basis: auto !important;
}

.theme--light.v-stepper .v-stepper__step--editable:hover {
  background: transparent !important;
}
</style>
