<template>
  <div
    class="intro-buttons"
    v-if="
      this.$store.state.core.widgetParameters.displayIntroButtons &&
      buttonsList.length
    "
  >
    <div
      class="botcells-intro-form-card mt-2 mb-2 intro-button-item"
      v-for="(button, index) in $store.state.core.introButtons"
      :key="index"
      @click="clickBtn(button)"
    >
      <div class="d-flex align-center">
        <img :src="button.buttonIcon" alt="" class="pr-2 pl-2" height="30" />
        <h5>{{ button.buttonLabel }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introButtons",
  data() {
    return {
      buttonsList: [
        {
          buttonLabel: "Button1",
          buttonIcon:
            "https://botcells-dev.fra1.digitaloceanspaces.com/2021-09-02/logo.8878de79.png",
          buttonType: "block",
          block: 1685,
          url: "",
          phone: "",
        },
        {
          buttonLabel: "button2",
          buttonIcon:
            "https://botcells-dev.fra1.digitaloceanspaces.com/2021-09-02/logo.8878de79.png",
          buttonType: "url",
          block: "",
          url: "https://github.com/",
          phone: "",
        },
        {
          buttonLabel: "button3",
          buttonIcon:
            "https://botcells-dev.fra1.digitaloceanspaces.com/2021-09-02/logo.8878de79.png",
          buttonType: "phone",
          block: "",
          url: "",
          phone: "0100000000000",
        },
      ],
    };
  },
  methods: {
    clickBtn(button) {
      if (button.buttonType === "block") {
        this.$store.dispatch("setInitBlock", button);
        this.$emit("start-chat");
      } else if (button.buttonType === "url") {
        window.open(button.url);
      } else if (button.buttonType === "phone") {
        window.open(`tel:${button.phone}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.botcells-intro-form-card {
  padding: 10px !important;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
}
</style>
