if (
  process.env.VUE_APP_ENVIROMENT === "production" ||
  process.env.VUE_APP_ENVIROMENT === "beta" ||
  process.env.VUE_APP_ENVIROMENT === "demos"
) {
  var cssId = "botcells-style"; // you could encode the css path itself to generate id..
  if (!document.getElementById(cssId)) {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = cssId;
    link.rel = "stylesheet";
    link.type = "text/css";

    link.href = process.env.VUE_APP_ENVIROMENT_URL
      ? `${process.env.VUE_APP_ENVIROMENT_URL}/app.css`
      : "https://widget.bluecrunch.org/app.css";

    link.media = "all";
    head.appendChild(link);
  }
}
