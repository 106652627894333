import Vue from "vue";
import App from "./App.vue";
import "./plugins/axios";
import store from "./store";
import "./utils/cssInjection";

// global components
import bBtn from "./components/UIElements/b-btn";

import { registerLicense } from "@syncfusion/ej2-base";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

Vue.component("b-btn", bBtn);

Vue.config.productionTip = false;
registerLicense(process.env.VUE_APP_EJ2_LICENSE_KEY);

setTimeout(() => {
  window.__botcells_chat__ = new Vue({
    el: "#botcells-widget-app",
    render: (h) => h(App),
    i18n,
    vuetify,
    store,
  }).$mount("#app");
}, 1000);
