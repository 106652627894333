<template>
  <div class="text botcells-conversation">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image
        :sender="con.sender"
        v-if="con.type === 'bot' && lastMessageGroup"
      />
      <!--      <a  class="botcells-attachment text-center" :href="con.mediaUrl || con.url" target="_blank">-->
      <!--        <v-icon>mdi-file-check-outline</v-icon>-->
      <!--        <p>{{con.fileName}}</p>-->
      <!--      </a>-->
      <a
        class="botcells-attachment"
        :class="getColor()"
        :href="con.mediaUrl || con.url"
        target="_blank"
      >
        <div
          class="botcells-attachment-preview"
          v-if="fileType === 'pdf' && fileThumb"
        >
          <img :src="fileThumb" alt="" />
        </div>
        <div class="botcells-attachment-body" v-if="fileType">
          <div class="botcells-attachment-title d-flex align-center">
            <v-icon v-if="fileType && fileTypes[fileType]">{{
              fileTypes[fileType].icon
            }}</v-icon>
            <div class="mr-2 ml-2">
              <p v-if="con.fileName">{{ con.fileName }}</p>
              <p v-else>{{ fileType }} File</p>
              <div class="botcells-attachment-fotter">
                <p>
                  {{ fileType }} - {{ fileSize }}
                  <span v-if="fileNumOfPages > 0"
                    >- {{ fileNumOfPages }} {{ $t("Pages") }}</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div>
            <v-icon>mdi-file-download-outline</v-icon>
          </div>
        </div>
      </a>
    </div>
    <div
      v-if="lastMessageGroup"
      class="chat-date d-flex"
      :class="getAlignment()"
    >
      {{ common.formatDate(con.date) }}
    </div>
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
import generatePdfThumbnails from "pdf-thumbnails-generator";
export default {
  name: "conversation",
  components: {
    userChatImage,
  },
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      common,
      fileTypes: {
        pdf: {
          icon: "mdi-file-pdf-outline",
        },
        docx: {
          icon: "mdi-file-word-outline",
        },
        pptx: {
          icon: "mdi-file-powerpoint-outline",
        },
        xlsx: {
          icon: "mdi-file-excel-outline",
        },
        undefined: {
          icon: "mdi-file-outline",
        },
      },
      fileType: "",
      fileSize: "",
      fileThumb: "",
      fileNumOfPages: 0,
    };
  },
  mounted() {
    if (this.con.attachment_type) {
      this.fileType = this.con.attachment_type.split(".").pop();
    } else {
      this.fileType = this.con.fileName.split(".").pop();
    }
    this.getFileSize();
    if (this.fileType === "pdf") {
      this.getFileThumb();
    }
  },
  methods: {
    getAlignment() {
      if (this.con.type === "user") {
        return "justify-end";
      } else if (this.con.type === "bot") {
        return "justify-start";
      }
    },
    getColor() {
      if (this.con.type === "user") {
        return "botcells-user-text";
      } else if (this.con.type === "bot") {
        return "botcells-bot-text";
      }
    },
    getFileSize() {
      var xhr = new XMLHttpRequest();
      xhr.open("HEAD", this.con.mediaUrl || this.con.url, true); // Notice "HEAD" instead of "GET",
      //  to get only the header
      xhr.onreadystatechange = () => {
        if (this.readyState === this.DONE) {
          let fileSize = parseInt(xhr.getResponseHeader("Content-Length"));
          if (fileSize > 1000000) {
            this.fileSize = (fileSize / 1000000).toFixed(2) + " MB";
          } else {
            this.fileSize = (fileSize / 1000).toFixed(0) + " KB";
          }
        }
      };
      xhr.send();
    },
    async getFileThumb() {
      try {
        const thumbnails = await generatePdfThumbnails(
          this.con.mediaUrl || this.con.url,
          312
        );
        this.fileThumb = thumbnails[0].thumbnail;
        this.fileNumOfPages = thumbnails.length;
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    lastMessageGroup() {
      return this.$store.state.messages.conversationList[this.index]
        .lastMessageGroup;
    },
  },
};
</script>

<style lang="scss">
.botcells-attachment {
  max-width: 100% !important;
  margin-top: 10px;
  background: #eaf0f7;
  width: 100%;
  border-radius: 5px;
  padding: 4px;
  .botcells-attachment-body {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
  }
  .v-icon {
    font-size: 30px;
  }
  p {
    word-break: break-all;
  }
  .botcells-attachment-fotter {
    p {
      font-size: 9px !important;
      opacity: 0.5;
      text-transform: uppercase;
    }
  }
  .botcells-attachment-preview {
    img {
      height: 100px;
      object-fit: cover;
      width: 100%;
      object-position: 0 0;
    }
  }
}
</style>
