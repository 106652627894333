<template>
  <div id="dynamicCss">
    <v-style
      v-if="
        !isStrictEnvironment &&
        widgetConfigurations.font &&
        !widgetConfigurations.customFont
      "
    >
      @import url('https://fonts.googleapis.com/css?family={{
        widgetConfigurations.font
      }}');
    </v-style>
    <v-style>
      .botcells--widget p , .botcells--widget h1, .botcells--widget h2,
      .botcells--widget h3, .botcells--widget h4, .botcells--widget h5,
      .botcells--widget h6, .botcells--widget span, .botcells--widget label,
      input , .booking-steps { font-family: '{{ widgetFont }}', 'sans-serif'
      !important; } #botcells-widget .botcells-user-text { background-color:
      {{ widgetConfigurations.userSideColor }} !important; } #botcells-widget
      .botcells-user-text p , #botcells-widget .botcells-user-text .v-icon ,
      #botcells-widget .v-application .botcells--widget .botcells-user-text
      .botcells-hyber-link { color:
      {{ widgetConfigurations.userSideFontColor }} !important; }
      #botcells-widget .botcells-bot-text , #botcells-widget .botcells-gallery
      .botcells-gallery-item , #botcells-widget .botcells-map-card ,
      #botcells-widget .botcells-book-card { background-color:
      {{ widgetConfigurations.chatBotAnswerColor }} !important; color:
      {{ widgetConfigurations.chatBotAnswerFontColor }} !important; }
      #botcells-widget .botcells-gallery .botcells-gallery-item p ,
      #botcells-widget .botcells-gallery .botcells-gallery-item h3,
      #botcells-widget .v-application .botcells--widget .botcells-hyber-link {
      color: {{ widgetConfigurations.chatBotAnswerFontColor }} !important; }
      #botcells-widget .botcells-bot-text p , #botcells-widget
      .botcells-gallery-item{ color:
      {{ widgetConfigurations.chatBotAnswerFontColor }} !important; }
      #botcells-widget .botcells-intro .botcells-intro-header , #botcells-widget
      .botcells-modal-head , #botcells-widget .botcells-intro
      .botcells-intro-header h3 , #botcells-widget .botcells-intro
      .botcells-intro-header p , #botcells-widget .botcells-agents-list h5 ,
      #botcells-widget .botcells-agents-list .botcells-agent-status p{ color:
      {{ widgetConfigurations.headerFontColor }} !important; } #botcells-widget
      .botcells-intro .botcells-intro-header , #botcells-widget
      .botcells-modal-head { background:
      {{ widgetConfigurations.headerColor }} !important; } #botcells-widget
      .botcells-modal-toggle-btn { background-color:
      {{ widgetConfigurations.bubbleButtonColor }} !important; }
      #botcells-widget .botcells--widget p { font-size:
      {{ widgetConfigurations.fontSize }}px !important; } #botcells-widget
      .botcells-modal-toggle-btn-whatsapp { background-color:
      {{ widgetConfigurations.whatsAppBtnColor }} !important; } #botcells-widget
      .botcells-modal-toggle-btn-web { background-color:
      {{ widgetConfigurations.webBtnColor }} !important; } #botcells-widget
      .botcells-btn { color: {{ widgetConfigurations.buttonsColor }} !important;
      background-color:
      {{ widgetConfigurations.buttonsBackgroundColor }} !important;
      border-color: {{ widgetConfigurations.buttonsBorderColor }} !important; }
      #botcells-widget .intro-button-item { background-color:
      {{ widgetConfigurations.introButtonsBackground }} !important; color:
      {{ widgetConfigurations.introButtonsColor }} !important; }
    </v-style>
    <v-style v-if="widgetConfigurations.position === 'left'">
      #botcells-widget .botcells-modal , #botcells-widget
      .botcells-modal-toggle-btn, #botcells-widget .botcells-offline-message ,
      #botcells-widget .botcells-channels-btns-wrapper { right: auto !important;
      left: 25px; } #botcells-widget .botcells-offline-message-icon { right:
      auto !important; left: 7px; }
    </v-style>
    <v-style v-if="lang === 'ar_AR'">
      #botcells-widget .botcells--widget { direction: rtl; } #botcells-widget
      .botcells-wdiget-body .botcells-chat-action , #botcells-widget
      .botcells-modal-head , #botcells-widget .botcells-wdiget-body
      .botcells-modal-chat{ direction: ltr; } #botcells-widget
      .v-text-field--outlined .v-input__append-outer , #botcells-widget
      .botcells-continue .mdi{ transform: rotate(180deg); } #botcells-widget
      .botcells-faq-search .v-input__slot { padding: 0 12px; border-radius: 0px
      5px 5px 0px !important; } #botcells-widget .botcells-intro
      .botcells-close-mobile { left: 41px; right: auto !important; }
      #botcells-widget .botcells-intro .botcells-intro-forms { text-align: right
      !important; } #botcells-widget .booking-steps{ direction: rtl; }
      #botcells-widget .ocr-steps{ direction: rtl; } #botcells-widget
      .booking-steps .step-actions .v-icon{ transform: rotate(180deg); }
      #botcells-widget .booking-steps .v-date-picker-header .v-icon { transform:
      rotate(180deg); } #botcells-widget .booking-steps h2 { margin-right: 0;
      margin-left: 50px }
    </v-style>
    <v-style v-if="widgetConfigurations.chatBubble === 'rounded'">
      #botcells-widget .botcells-bot-text { border-radius: 0px 20px 20px 20px
      !important; } #botcells-widget .botcells-user-text { border-radius: 20px 0
      20px 20px !important; }
    </v-style>
    <v-style v-if="widgetConfigurations.chatBubble === 'curvey'">
      #botcells-widget .botcells-bot-text { border-radius: 0px 5px 5px 5px
      !important; } #botcells-widget .botcells-user-text { border-radius: 5px 0
      5px 5px !important; }
    </v-style>
    <v-style v-if="widgetConfigurations.logoColorType === 'white'">
      #botcells-widget .botcells-intro-header img { filter: brightness(0)
      invert(1); }
    </v-style>
    <v-style v-if="widgetConfigurations.botAvatarColorType === 'white'">
      #botcells-widget .botcells-bot-chat-image img , #botcells-widget
      .botcells-offline-message-avatar img , #botcells-widget
      .botcells-intro-form-card .botcells-agent-avatar img { -webkit-filter:
      brightness(0) invert(1); filter: brightness(0) invert(1); }
    </v-style>
    <v-style v-if="widgetConfigurations.botAvatarColorType === 'colored'">
      #botcells-widget .botcells-bot-chat-image img{ filter: none !important; }
    </v-style>
    <v-style v-if="widgetConfigurations.openBubbleImageColorType === 'colored'">
      #botcells-widget .botcells-modal-toggle-btn .botcells-modal-open-btn ,
      #botcells-widget .botcells-modal-toggle-btn-web .botcells-modal-open-btn ,
      #botcells-widget .botcells-modal-toggle-btn-whatsapp
      .botcells-modal-open-btn { filter: none !important; }
    </v-style>
    <v-style v-if="!inIframe">
      .botcells-widget-body { background-image: url({{
        widgetConfigurations.demoBackground
      }}); background-size: 100%; } .widget-iframe-wrapper .botcells-widget-body
      , #botcells-iframe .botcells-widget-body { background-image: none
      !important; }
    </v-style>
    <v-style>
      #botcells-widget .botcells-bot-chat-image, #botcells-widget
      .botcells-offline-message .botcells-offline-message-wrapper
      .botcells-offline-message-avatar, #botcells-widget
      .botcells-intro-form-card .botcells-agent-avatar { background-color:
      {{ widgetConfigurations.avatarBackgroundColor }} !important; }
    </v-style>
    <v-style>
      #botcells-widget .botcells-offline-message
      .botcells-offline-message-wrapper .botcells-offline-message-body {
      background-color:
      {{ widgetConfigurations.offLineMessageBackgroundColor }} !important; }
      #botcells-widget .botcells-offline-message path { fill:
      {{ widgetConfigurations.offLineMessageBackgroundColor }} !important; }
      #botcells-widget .botcells-offline-message
      .botcells-offline-message-wrapper .botcells-offline-message-body p {
      color: {{ widgetConfigurations.offLineMessageFontColor }} !important }
    </v-style>
    <v-style v-if="widgetConfigurations.openBubbleImageFullWidth">
      #botcells-widget .botcells-modal-toggle-btn .botcells-modal-open-btn ,
      #botcells-widget .botcells-modal-toggle-btn-web .botcells-modal-open-btn ,
      #botcells-widget .botcells-modal-toggle-btn-whatsapp
      .botcells-modal-open-btn{ width: 100%; height: 100%; }
    </v-style>
    <v-style v-if="widgetConfigurations.botAvatarHasFullWidth">
      #botcells-widget .botcells-modal-head .botcells-agent-avatar img ,
      #botcells-widget .botcells-bot-chat-image img { height: 100%; width: 100%;
      } #botcells-widget .botcells-modal-head .botcells-agent-avatar { padding:
      0 !important; }
    </v-style>
  </div>
</template>

<script>
export default {
  name: "dynamicCSS",
  data() {
    return {
      inIframe: false,
      isStrictEnvironment: false,
      widgetFont: "Roboto",
    };
  },
  created() {
    this.isStrictEnvironment = process.env["VUE_APP_IS_STRICT_ENVIRONMENT"]
      ? Boolean(parseInt(process.env.VUE_APP_IS_STRICT_ENVIRONMENT))
      : false;
  },
  mounted() {
    this.inIframe = window.top !== window.self;

    this.$i18n.locale = this.$store.state.core.locale;

    if (window.addEventListener) {
      window.addEventListener(
        "message",
        (e) => {
          if (e.data && e.data.mainColor) {
            this.widgetConfigurations = e.data;
            this.$vuetify.theme.themes.light.primary =
              this.widgetConfigurations.mainColor;
            document.documentElement.style.setProperty(
              "--primaryColor",
              this.widgetConfigurations.mainColor
            );
            this.$store.dispatch("setWidgetParameters", e.data);
          } else if (e.data.type === "reload") {
            location.reload();
          }
        },
        false
      );
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", () => {});
    }
  },
  methods: {},
  computed: {
    mainColor() {
      return this.widgetConfigurations.mainColor;
    },
    widgetConfigurations() {
      return this.$store.state.core.widgetParameters;
    },
    lang() {
      return this.$store.state.core.locale;
    },
  },
  watch: {
    widgetConfigurations: {
      handler(val) {
        this.$vuetify.theme.themes.light.primary = val.mainColor;
        document.documentElement.style.setProperty(
          "--primaryColor",
          this.mainColor
        );

        if (val.font && !this.isStrictEnvironment) {
          this.widgetFont = val.font;
        }
      },
      deep: true,
    },
    // mainColor() {
    //   this.$vuetify.theme.themes.light.primary = this.mainColor;
    //   document.documentElement.style.setProperty(
    //     "--primaryColor",
    //     this.mainColor
    //   );
    // },
  },
};
</script>

<style scoped></style>
