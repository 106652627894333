<template>
  <div>
    <div
      class="botcells-modal-toggle-btn"
      @click="$emit('openModal')"
      v-show="!$store.state.core.widgetParameters.hideOpenButton"
      v-if="!$store.state.core.widgetParameters.whatsAppChatButtonLink"
    >
      <img
        class="botcells-modal-open-btn"
        :src="$store.state.core.widgetParameters.openBubbleImage"
        alt=""
      />
      <img
        class="botcells-modal-close-btn"
        :src="$store.state.core.widgetParameters.closeBubbleImage"
        alt=""
      />
    </div>
    <div v-else>
      <div
        v-if="!$store.state.core.widgetParameters.whatsAppMandatory"
        class="botcells-modal-toggle-btn-multiple"
      >
        <div class="botcells-modal-toggle-btn" @click="openChannelsList">
          <img
            class="botcells-modal-open-btn"
            :src="$store.state.core.widgetParameters.openBubbleImage"
            alt=""
          />
          <img
            class="botcells-modal-close-btn"
            :src="$store.state.core.widgetParameters.closeBubbleImage"
            alt=""
          />
        </div>
        <div class="botcells-channels-btns-wrapper">
          <div
            class="botcells-channels-btns"
            :class="{ activeChannels: activeChannels }"
          >
            <div
              class="botcells-modal-toggle-btn-whatsapp nds nd1"
              @click="openWhatsAppLink"
            >
              <img
                class="botcells-modal-open-btn"
                :src="$store.state.core.widgetParameters.whatsAppBtnImg"
                alt=""
              />
            </div>
            <div
              class="botcells-modal-toggle-btn-web nds nd2"
              @click="openWebWidget"
            >
              <img
                class="botcells-modal-open-btn"
                :src="$store.state.core.widgetParameters.webBtnImg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="botcells-modal-toggle-btn-whatsapp-mandatory botcells-modal-toggle-btn"
        v-else
        @click="openWhatsAppLinkSingle"
      >
        <img
          class="botcells-modal-open-btn"
          :src="$store.state.core.widgetParameters.whatsAppBtnImg"
          alt=""
        />
        <img
          class="botcells-modal-close-btn"
          :src="$store.state.core.widgetParameters.closeBubbleImage"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "openButton",
  data() {
    return {
      activeChannels: false,
      activeChannelsBtns: false,
    };
  },
  mounted() {
    // setTimeout(()=>{
    //   if (document.getElementsByClassName('botcells-channels-btns')[0]) {
    //     document.getElementsByClassName('botcells-channels-btns')[0].style.visibility = 'visible'
    //   }
    // },1000)
  },
  methods: {
    openWhatsAppLink() {
      this.activeChannels = !this.activeChannels;
      window.top.postMessage("toggleChannels", "*");
      document
        .getElementsByClassName("botcells-modal-toggle-btn")[0]
        .classList.toggle("active-channels-btn");
      window.top.open(
        this.$store.state.core.widgetParameters.whatsAppChatButtonLink
      );
    },
    openWhatsAppLinkSingle() {
      window.top.open(
        this.$store.state.core.widgetParameters.whatsAppChatButtonLink
      );
    },
    openChannelsList() {
      let modalActive = document
        .getElementsByClassName("botcells-modal")[0]
        .classList.contains("botcells-modal-active");
      if (modalActive) {
        this.$emit("openModal");
      } else {
        this.activeChannels = !this.activeChannels;
        window.top.postMessage("toggleChannels", "*");
        document
          .getElementsByClassName("botcells-modal-toggle-btn")[0]
          .classList.toggle("active-channels-btn");
        this.$emit("activeChannels", this.activeChannels);
      }
    },
    openWebWidget() {
      this.activeChannels = !this.activeChannels;
      window.top.postMessage("toggleChannels", "*");
      setTimeout(() => {
        document
          .getElementsByClassName("botcells-modal-toggle-btn")[0]
          .classList.toggle("active-channels-btn");
      }, 1000);
      this.$emit("openModal");
    },
  },
};
</script>

<style scoped lang="scss">
.botcells-modal-toggle-btn-whatsapp,
.botcells-modal-toggle-btn-web {
  position: static !important;
}

.botcells-channels-btns-wrapper {
  position: fixed;
  bottom: 90px;
  right: 25px;
}

.botcells-channels-btns {
  visibility: hidden;
}

.botcells-modal-toggle-btn-multiple {
  position: relative;
}

.nds {
  border-radius: 50%;
  z-index: 300;
  transform: scale(0);
}

.nd1 {
  animation-delay: 0.2s;
  animation: bounce-out-nds 0.3s linear;
  animation-fill-mode: forwards;
}
.nd2 {
  animation-delay: 0.15s;
  animation: bounce-out-nds 0.15s linear;
  animation-fill-mode: forwards;
}

@keyframes bounce-nds {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

.activeChannels {
  visibility: visible;
}

.activeChannels .nds {
  animation: bounce-nds 0.1s linear;
  animation-fill-mode: forwards;
}

.activeChannels .nd1 {
  animation-delay: 0.15s;
}
.activeChannels .nd2 {
  animation-delay: 0.08s;
}
.activeChannels .nd3 {
  animation-delay: 0.2s;
}

.activeChannels .botcells-modal-toggle-btn {
  transform: rotate(315deg);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: whitesmoke;
  text-align: center;
  border-radius: 8px;
  padding: 5px 0;
  font-size: 11px;
  /* Position the tooltip */
  top: 50%;
  transform: translateY(-50%);
  right: 32px;
  position: absolute;
  z-index: 1;
}
.active-channels-btn {
  .botcells-modal-close-btn {
    width: 20px;
    opacity: 1;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .botcells-modal-open-btn {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
}
</style>
