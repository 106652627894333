<template>
  <div>
    <div class="botcells-weather-card-container">
      <div
        v-if="con && con.data"
        class="botcells-weather-card d-flex align-end justify-start"
      >
        <div class="full-width">
          <p>
            <b>{{ con.data.area }}</b>
          </p>
          <p>
            <small>
              {{ currentDate }}
            </small>
          </p>
          <div class="v-row full-width">
            <img :src="con.data.imageUrl" class="botcells-weather-thumbnail" />
            <div class="botcells-weather-data v-row">
              <div class="botcells-weather-temp">
                <b>{{ con.data.temp }}</b>
              </div>
              <!--				<div class=""><label><small>&#8457;</small></label></div>-->
              <div class="full-width text-right">
                <div class="">{{ $t("High") }} {{ con.data.highTemp }}</div>
                <div class="">{{ $t("Low") }} {{ con.data.lowTemp }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dates from "../../mixins/dates";
export default {
  name: "weatherData",
  mixins: [dates],
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    currentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return mm + "/" + dd + "/" + yyyy;
    },
  },
};
</script>

<style lang="scss" scoped>
.botcells-weather-card-container {
  padding-left: 40px !important;
  width: calc(100% - 40px);
}
.botcells-weather-card {
  border: 1px solid $border_color;
  border-radius: $standard_border-radius;
  padding: 12px;
  margin-top: 10px;
}
.botcells-weather-thumbnail {
  width: 50px;
  height: 50px;
}
.botcells-weather-data {
  padding: 4px 14px;
  .botcells-weather-temp {
    font-size: 24px;
    margin-top: 6px;
  }
}
</style>
