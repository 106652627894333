<template>
  <v-app>
    <botcells-widget></botcells-widget>
    <dynamic-css />
  </v-app>
</template>

<script>
import botcellsWidget from "./components/botcellsWidget";
import dynamicCss from "./components/dynamicCSS";
export default {
  name: "App",
  components: {
    botcellsWidget,
    dynamicCss,
  },

  data() {
    return {
      window: {},
    };
  },
};
</script>

<style>
@import "style/helpers.scss";
@import "style/normalize.scss";
@import "style/vuetify.scss";
@import "style/components/steppers.scss";
</style>
