var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modalContainer",staticClass:"botcells-wdiget-body"},[_c('div',{class:[
      'botcells-modal-chat',
      { 'botcells-chat-body-shrinked': _vm.inputPulledUp },
    ]},[_c('div',{staticClass:"botcells-modal-chat-container"},[_c('transition-group',{attrs:{"name":"slide"}},_vm._l((_vm.conversation),function(con,index){return _c('div',{key:`${con.slug}${index}`},[(_vm.inputPulledUp || !_vm.isInputPullingSlug(con.slug))?_c(_vm.getFileName(con.slug),{tag:"component",attrs:{"con":con,"index":index},on:{"postBack":_vm.postBack,"postText":_vm.postText,"postFile":_vm.postFile,"postEvent":_vm.postEvent,"postCustom":_vm.postCustom,"update-input":_vm.handleUpdateInput}}):_vm._e()],1)}),0)],1)]),(!(this.$store.state.core.widgetParameters.hidePoweredBy === true))?_c('div',{staticClass:"botcells-chat-infos"},[_c('a',{attrs:{"dir":"ltr","href":"https://botcells.com/","target":"_blank"}},[_c('botcells-logo',{attrs:{"width":10,"height":20}}),_c('span',{staticClass:"ml-0"},[_vm._v("Powered by BOTCELLS")])],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inputDisabled),expression:"!inputDisabled"}],class:[
      'botcells-chat-action',
      { 'botcells-chat-action-pulled-up': _vm.inputPulledUp },
    ]},[(!_vm.connected)?_c('snackbar',[_c('bdi',[_vm._v(_vm._s(_vm.$t("You are disconnected we are trying to reconnect again")))])]):_vm._e(),_c('div',{staticClass:"d-flex align-center relative justify-space-between full-width"},[(
          !(this.$store.state.core.widgetParameters.hideRecorder === true) &&
          _vm.showRecorder &&
          _vm.connected
        )?_c('record-voice',{on:{"record":_vm.handleRecordCompleteAction,"cancel":_vm.handleRecordCancelAction}}):_vm._e(),_c('form',{staticClass:"botcells-user-input d-flex align-center",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('presist-menu',{staticStyle:{"height":"28px"},on:{"postBack":_vm.postBack}}),(!_vm.inputPulledUp)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInput),expression:"userInput"}],staticClass:"botcells-user-input-field",attrs:{"disabled":!_vm.connected || _vm.inputDisabled,"type":"text","id":"botcells-user-input-field","spellcheck":"true","placeholder":_vm.$store.state.core.widgetParameters.inputPlaceHolder ||
            'Write a reply'},domProps:{"value":(_vm.userInput)},on:{"input":function($event){if($event.target.composing)return;_vm.userInput=$event.target.value}}}):_c('div',{staticClass:"botcells-user-input-label"},[_c('p',[_vm._v(_vm._s(_vm.inputLabel))])])],1),(_vm.connected)?_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"width":"70px"}},[(
            !_vm.$store.state.core.widgetParameters.showLocationLocator &&
            !(this.$store.state.core.widgetParameters.hideShareFile === true)
          )?_c('input',{ref:"file",staticClass:"botcells-file-uploader",attrs:{"type":"file"},on:{"change":_vm.uploadFile}}):_vm._e(),(
            !_vm.$store.state.core.widgetParameters.showLocationLocator &&
            !(this.$store.state.core.widgetParameters.hideShareFile === true)
          )?_c('span',{staticClass:"botcells-svg d-flex align-center",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"19.798","height":"19.798","viewBox":"0 0 19.798 19.798"}},[_c('g',{staticStyle:{"opacity":"0.7"},attrs:{"id":"prefix__noun_Paperclip_2766516","transform":"translate(-.086 -.086)"}},[_c('g',{attrs:{"id":"prefix__Group_21","data-name":"Group 21","transform":"translate(.086 .086)"}},[_c('path',{attrs:{"id":"prefix__Path_6","d":"M7.636 4.242V14A2.969 2.969 0 0 1 1.7 14V3.394a1.7 1.7 0 1 1 3.394 0v9.757a.424.424 0 1 1-.848 0V4.242h-1.7v8.908a2.121 2.121 0 0 0 4.242 0V3.394a3.394 3.394 0 1 0-6.788 0V14a4.666 4.666 0 0 0 9.333 0V4.242z","data-name":"Path 6","transform":"rotate(-135 7.4 8.532)"}})])])])]):_vm._e(),_c('div',{staticClass:"widget-action-trigger",class:{ activeActions: _vm.activeActions }},[(_vm.$store.state.core.widgetParameters.showLocationLocator)?_c('v-icon',{staticClass:"plug-trigger",on:{"click":function($event){_vm.activeActions = !_vm.activeActions}}},[_vm._v("mdi-plus ")]):_vm._e(),(_vm.$store.state.core.widgetParameters.showLocationLocator)?_c('widget-actions',[_c('location-locator',{staticClass:"nds nd1",on:{"getLocation":_vm.postLocation}}),_c('div',{attrs:{"id":"file-uploader"}},[_c('input',{ref:"file",staticClass:"botcells-file-uploader",attrs:{"type":"file"},on:{"change":_vm.uploadFile}}),_c('div',{staticClass:"botcells-svg nds nd2",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('div',{staticClass:"tooltip"},[_c('v-icon',[_vm._v("mdi-attachment")]),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t("send attachment")))])],1)])])],1):_vm._e()],1),(_vm.userInput.length)?_c('send-button',{on:{"click":_vm.handleSendAction}}):_vm._e(),(
            _vm.connected &&
            !_vm.userInput.length &&
            !(
              this.$store.state.core.widgetParameters
                .hideVoiceNoteRecorder === true
            )
          )?_c('record-button',{on:{"click":_vm.handleRecordAction}}):_vm._e(),_c('div',{attrs:{"id":"sound"}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }