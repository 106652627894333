<template>
  <div class="text botcells-conversation botcells-hero botcells-trivia">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image
        :sender="con.sender"
        v-if="con.type === 'bot' && lastMessageGroup"
      />
      <div class="full-width">
        <div class="botcells-text" :class="getColor()" v-if="con.payload">
          <p
            v-html="common.formatText(con.payload)"
            :class="[common.getTextAlignment(con.payload), getColor()]"
          ></p>
        </div>
      </div>
    </div>

    <div class="botcells-buttons-list" v-if="displayBtn">
      <div
        class="d-block"
        v-for="(btn, index) in con.actions"
        :key="index"
        @click="displayBtn = false"
      >
        <div v-if="btn.title.length">
          <a
            :href="`tel:${btn.value}`"
            target="_blank"
            v-if="btn.type === 'call'"
          >
            <b-btn>
              {{ btn.title }}
            </b-btn>
          </a>
          <a :href="btn.value" target="_blank" v-if="btn.type === 'openUrl'">
            <b-btn>
              {{ btn.title }}
            </b-btn>
          </a>
          <div @click="postBack(btn)">
            <b-btn v-if="btn.type === 'postBack'">
              {{ btn.title }}
            </b-btn>
          </div>
          <div @click="callEvent(btn.value)">
            <b-btn v-if="btn.type === 'event'">
              {{ btn.title }}
            </b-btn>
          </div>
        </div>
      </div>
    </div>
    <!--    <div v-if="lastMessageGroup" class="chat-date d-flex" :class="getAlignment()">{{common.formatDate(con.date)}}</div>-->
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import common from "../../utils/common";
import userChatImage from "./common/userChatImage";
export default {
  name: "teriviaQuestion",
  components: {
    userChatImage,
    Carousel,
    Slide,
  },
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      common,
      displayBtn: true,
    };
  },
  mounted() {
    this.$store.dispatch("setInputDisabled", true);
  },
  methods: {
    getAlignment() {
      if (this.con.type === "user") {
        return "justify-end";
      } else if (this.con.type === "bot") {
        return "justify-start";
      }
    },
    getColor() {
      if (this.con.type === "user") {
        return "botcells-user-text";
      } else if (this.con.type === "bot") {
        return "botcells-bot-text";
      }
    },
    postBack(data) {
      this.$store.dispatch("pushMessage", {
        slug: "text",
        payload: data.title,
        type: "user",
        lastMessageGroup: true,
      });
      this.$emit("postBack", data);
      this.$store.dispatch("setInputDisabled", false);
    },
    callEvent(value) {
      this.$emit("postEvent", value);
      try {
        webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({ msg_code: value })
        );
      } catch (e) {
        // alert(e.message)
      }
    },
  },
  computed: {
    lastMessageGroup() {
      return this.$store.state.messages.conversationList[this.index]
        .lastMessageGroup;
    },
  },
};
</script>

<style lang="scss">
.trivia {
  .VueCarousel-inner {
    // flex-basis: auto !important;
  }
  .VueCarousel-navigation-button {
    background-color: white !important;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    outline: none !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }

  .VueCarousel-navigation-next {
    right: 0px !important;
  }

  .VueCarousel-navigation-prev {
    left: -8px !important;
  }
}
</style>
