<template>
  <div class="text botcells-conversation">
    <p class="text-center mt-2">{{ con.notify_text }}</p>
  </div>
</template>

<script>
export default {
  name: "escalateData",
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped></style>
