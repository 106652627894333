import Vue from "vue";
import Vuex from "vuex";
import { messages } from "./modules/messages";
import { core } from "./modules/core";
import { bookingSteps } from "./modules/bookingSteps";
import { ocrSteps } from "./modules/ocrSteps";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    messages,
    core,
    bookingSteps,
    ocrSteps,
  },
});

export default store;
